import { Component, EventEmitter, Input, Output, Renderer2 } from '@angular/core';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent {

  constructor(public render: Renderer2) { }

  @Input('placeholder')
  placeholder: string = '';

  @Input('list')
  list: any[] = [];

  noResultMsg: string = 'Nothing matches your keywords';
  searchText: string = '';

  @Output() selectItemEvent = new EventEmitter<any>()

  onGlobalSearch(event: any): void {
    this.searchText = event.queryStr;
    if (event.result && event.result.length > 0) {
      this.list = event.result;
      let length = this.list.length;
      for (let i = 0; i < this.list.length; i++) {
        if (this.list[i].disabled) {
          length = length - 1;
        }
      }
    } else {
      this.list = [];
    }
  }

  onClear(event: any): void {
    this.list = [];
  }

  onGlobalSelectItem(event: any): void {
    this.selectItemEvent.emit(event.selected)
  }

  onMouseEnterItem(event: any): void {
    this.render.addClass(event.originEvent.target, 'iconLink');
  }

  onMouseLeaveItem(event: any): void {
    this.render.removeClass(event.originEvent.target, 'iconLink')
  }



}
