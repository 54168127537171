import { Injectable } from "@angular/core";
import { NotificationService } from '@appkit4/angular-components/notification';

@Injectable({
    providedIn: 'root'
})
export class CustomNotificationService {
    id: string = 'notification1'
    duration: number = 2000

    constructor(protected notificationService: NotificationService) { }

    success(msg: string) {
        this.createNotification(msg, "icon-success-fill")
    }

    warning(msg: string) {
        this.createNotification(msg, "icon-warning-fill")
    }

    error(msg: string) {
        this.createNotification(msg, "icon-error-fill", 'An error has occurred.', 4000)
    }

    private createNotification(msg: string, icon: string, title: string = '', duration?: number): void {
        this.notificationService
            .show(
                title,
                msg,
                '',
                '',
                {
                    duration: duration || this.duration,
                    id: this.id,
                    clickToClose: false,
                    showClose: true,
                    icon: icon,
                },
                false,
                false
            )
    }

}