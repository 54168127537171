<div class="row ap-my-spacing-5">
    <div class="col-12">
        <app-header-page [title]="'Archive'"></app-header-page>
    </div>
    <div class="col-12 col-lg-3 col-md-4 col-sm-6 ap-px-spacing-3 search-container">
        <ap-search #searchInput [placeholder]="'Search'" [searchType]="'secondary'" [disabled]="false"
            (onSearch)="search($event)" (onClear)="search($event)"
            [disabled]="!(callsheets$ | async)?.length && !searchInput.value || selectedPage > 1"></ap-search>
    </div>

    <div class="row" *ngIf="(callsheets$ | async) as callsheets">
        <div class="col-12 ap-mt-spacing-3">
            <app-callsheets-table [actionsList]="actions" [callsheets]="callsheets" [totalPages]="(showPages$ | async)!"
                (onPageChange)="onPageChange($event)" (onAction)="handleActions($event)"
                [showProjectUrl]="true" [archiveCallsheets]="true"></app-callsheets-table>
        </div>

        <div *ngIf="searchInput.value && !callsheets.length" class="d-flex flex-column align-items-center">
            <h2><i class="Appkit4-icon icon-search-not-found-outline"></i> Not found!</h2>
            <p class="not-found-msg">We could not find the callsheet you were looking for. </p>
        </div>

    </div>
</div>