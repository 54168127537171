<div class="ap-container ap-px-spacing-1">
    <div class="ap-mb-spacing-4">Please select the user you want to notify changes by email.</div>
    <div class="email-list">
        <div *ngFor="let item of notifications; let i = index;" class="ap-mb-spacing-3">
            <ng-container *ngIf="item.users.length">
                <div class="d-flex justify-content-between align-items-center border-row ap-p-spacing-3">
                    {{item.title}}
                    <label [for]="'select' + i" class="ap-text-color-text-primary ap-font-12">
                        <input type="checkbox" [id]="'select' + i" (change)="onSelectAll(item)" />
                        {{item.all ? 'Deselect all' : 'Select all'}}
                    </label>
                </div>
                <div *ngFor="let user of item.users; let i = index;"
                    class="d-flex justify-content-between align-items-center ap-px-spacing-3 ap-py-spacing-4"
                    [class.border-row]="i != item.users.length - 1">
                    <app-user-info [user]="user"></app-user-info>
                    <div class="d-flex flex-column justify-content-center align-items-end">
                        <ap-checkbox [tabindex]="-1" [(ngModel)]="user.checked" (onChange)="validate(item)"></ap-checkbox>
                        <div class="ap-font-12 ap-text-color-text-ligth" *ngIf="user?.lastNotification?.timestamp">{{'Notified on ' + (user.lastNotification.timestamp | date:'MM/dd/yyyy')}}</div>
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
    <ap-button class="d-flex justify-content-end ap-mt-spacing-6" [disabled]="!hasChecked"
        [icon]="'paper-airplane-outline'" [btnType]="'primary'" [label]="'Send emails'" (onClick)="send()"></ap-button>
</div>