import { APP_INITIALIZER, NgModule, Optional, SkipSelf } from '@angular/core'
import { CommonModule } from '@angular/common'
import { OauthAdapterService } from './oauth-adapter.service'
import { environment } from '../../../environments/env'
import { AuthExtensionModule, AuthExtensionModuleConfig } from '@rco/angular-auth-extensions'
import { AuthConfig, OAuthModule } from 'angular-oauth2-oidc'
import { oAuthAdapterInitializerFactory } from './oauth-adapter-initializer.factory'

// const moduleConfig: AuthExtensionModuleConfig = {
//   protectedUrls: [environment.serviceDomain]
// }

const authConfig: AuthConfig = {
  issuer: environment.discoveryUrl,
  clientId: environment.clientId,
  responseType: 'code',
  redirectUri: `${window.location.origin}/login/token`,
  redirectUriAsPostLogoutRedirectUriFallback: false,
  scope: environment.scope,
  showDebugInformation: true
}

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    OAuthModule.forRoot(),
    AuthExtensionModule.forRoot({
      authService: OauthAdapterService
    })
  ],
  exports: [OAuthModule, AuthExtensionModule],
  providers: [
    { provide: APP_INITIALIZER, useFactory: oAuthAdapterInitializerFactory, deps: [OauthAdapterService], multi: true },
    { provide: AuthConfig, useValue: authConfig }
  ]
})
export class OauthAdapterModule {
  constructor(@Optional() @SkipSelf() parentModule: OauthAdapterModule) {
    if (parentModule) {
      throw new Error('OauthAdapterModule is already loaded. Import it in the AppModule only')
    }
  }
}
