import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

// import custom components
import { ViewCallsheetComponent } from './pages/view/view.component';
import { ListCallsheetComponent } from './pages/list/list.component';
import { CreateCallsheetComponent } from './pages/create/create.component';
import { ArchiveCallsheetComponent } from './pages/archive/archive.component';
import { PwCGuard } from 'src/app/core/guards/pwc.guard';
import { CloneCallsheetComponent } from './pages/clone/clone.component';
import { SectionSelectorComponent } from './pages/clone/section-selector/section-selector.component';


const routes: Routes = [
  { path: '', component: ListCallsheetComponent, pathMatch: 'full' },
  { path: 'archive', component: ArchiveCallsheetComponent, canActivate: [PwCGuard] },
  { path: 'callsheet/:id', component: CreateCallsheetComponent },
  { path: 'callsheet/view/:id', component: ViewCallsheetComponent },
  { path: 'callsheet/clone/:id', component: CloneCallsheetComponent },
  { path: 'callsheet/clone/sections/:id', component: SectionSelectorComponent },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CallsheetsRoutingModule { }

export const CallsheetsRoutedComponents = [
  ListCallsheetComponent,
  ViewCallsheetComponent,
  CreateCallsheetComponent,
  CloneCallsheetComponent,
  SectionSelectorComponent
];