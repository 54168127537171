import { Component } from '@angular/core';
import { OauthAdapterService } from 'src/app/core/oauth';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent {

  constructor(private readonly authService: OauthAdapterService) { }

  onPwCSSOLogin($event: any) {
    this.authService.login('/')
  }
}
