import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ActionButton, ButtonDropdownOptions } from '../../interfaces/actions.interface';

@Component({
  selector: 'app-panel',
  templateUrl: './panel.component.html',
  styleUrls: ['./panel.component.scss']
})
export class PanelComponent {

  @Input() actions: ActionButton[] = [
    {
      icon: 'Appkit4-icon icon-edit-outline',
      action: 'edit',
      label: '',
      type: ''
    },
    {
      icon: 'Appkit4-icon icon-delete-outline',
      action: 'delete',
      label: '',
      type: ''
    }
  ]

  @Input() moreOptions!: ButtonDropdownOptions[];

  @Output() onActionEvent = new EventEmitter<string>();


  onClick(action: ActionButton) {
    this.onActionEvent.emit(action.action)
  }

  onSelectOption(e: any) {
    this.onActionEvent.emit(e.selected.value)
  }


}
