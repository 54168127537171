import { Component, OnInit } from '@angular/core';
import { CallsheetsService } from '../../callsheets.service';
import { Observable, switchMap, tap } from 'rxjs';
import { CallSheet, ShootingLocation } from 'src/app/shared/interfaces';
import { ActivatedRoute, Router } from '@angular/router';
import { WorkfrontService } from 'src/app/shared/services/workfront.service';
import { DateTime } from 'luxon';
import { WorkfrontProject } from 'src/app/shared/interfaces/workfront-project.interface';

@Component({
  selector: 'app-clone',
  templateUrl: './clone.component.html',
  styleUrls: ['./clone.component.scss']
})
export class CloneCallsheetComponent implements OnInit {

  constructor(private workfrontService: WorkfrontService, private callsheetsService: CallsheetsService, private router: Router, private route: ActivatedRoute,) { }

  callsheet$!: Observable<CallSheet>
  sourceCallsheet!: CallSheet;
  shootDate: Date[] = [];
  projectRefNumber: string = ""
  projectName: string = ""
  projectId: string = ""
  invalidId!: boolean;
  loading: boolean = false
  inputError!: boolean
  minDate: Date = new Date()



  async ngOnInit() {
    this.callsheet$ = this.route.params.pipe(
      switchMap((params: any) => {
        return this.callsheetsService.getById(params.id).pipe(
          tap((result: CallSheet) => {
            this.sourceCallsheet = result;
          })
        );
      })
    );
  }

  checkInput() {
    this.inputError = isNaN(Number(this.projectRefNumber));
  }

  searchProject() {
    this.loading = true;
    return this.workfrontService.getById(this.projectRefNumber).subscribe(
      (x: Object) => {
        const workfrontProject: WorkfrontProject = x as WorkfrontProject;
        this.loading = false;
        if (workfrontProject !== null) {
          this.invalidId = false;
          this.projectId = workfrontProject.projID;
          this.projectName = workfrontProject.projectName
        } else {
          this.invalidId = true;
        }
      }
    );
  }

  combineDateAndTime(location: ShootingLocation, shootDate: Date, shootTime: Date) {
    return DateTime.local({ zone: `UTC${location.timezone}` }).set({
      year: shootDate.getFullYear(),
      month: shootDate.getMonth() + 1,
      day: shootDate.getDate(),
      hour: shootTime.getHours(),
      minute: shootTime.getMinutes(),
      second: shootTime.getSeconds(),
      millisecond: shootTime.getMilliseconds(),
    }).toJSDate()
  }

  cloneWholeCallsheet() {
    const payload: CallSheet = {
      ...this.sourceCallsheet,
      referenceID: this.projectRefNumber,
      projID: this.projectId,
      projectName: this.projectName,
      createdAt: new Date(),
      updatedAt: new Date(),
      shootDate: this.combineDateAndTime(this.sourceCallsheet.location, this.shootDate[0], this.sourceCallsheet.shootDate),
      filmingStart: this.sourceCallsheet.filmingStart ? this.combineDateAndTime(this.sourceCallsheet.location, this.shootDate[0], this.sourceCallsheet.filmingStart!) : undefined,
      talentCall: this.sourceCallsheet.talentCall ? this.combineDateAndTime(this.sourceCallsheet.location, this.shootDate[0], this.sourceCallsheet.talentCall!) : undefined
    }

    this.callsheetsService.create(payload).subscribe(x => {
      this.router.navigate(['/callsheet/', x._id!]);
    });
  }

  selectSectionsToClone() {
    const payload: CallSheet = {
      ...this.sourceCallsheet,
      referenceID: this.projectRefNumber,
      projID: this.projectId,
      projectName: this.projectName,
      createdAt: new Date(),
      updatedAt: new Date(),
      shootDate: this.combineDateAndTime(this.sourceCallsheet.location, this.shootDate[0], this.sourceCallsheet.shootDate),
      filmingStart: this.sourceCallsheet.filmingStart ? this.combineDateAndTime(this.sourceCallsheet.location, this.shootDate[0], this.sourceCallsheet.filmingStart!) : undefined,
      talentCall: this.sourceCallsheet.talentCall ? this.combineDateAndTime(this.sourceCallsheet.location, this.shootDate[0], this.sourceCallsheet.talentCall!) : undefined
    }

    this.callsheetsService.create(payload).subscribe(x => {
      this.router.navigate(['/callsheet/clone/sections', x._id!])
    });
  }






}
