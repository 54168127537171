import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { RouterModule } from '@angular/router'
import { OauthAdapterModule } from './oauth/oauth-adapter.module'
import { AuthGuard } from './guards/auth.guard'
import { PwCGuard } from './guards/pwc.guard'

@NgModule({
  imports: [CommonModule, RouterModule, OauthAdapterModule],
  declarations: [],
  exports: [
    OauthAdapterModule
  ],
  providers: [AuthGuard, PwCGuard]
})
export class CoreModule {}
