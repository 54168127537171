import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CallSheet, User } from 'src/app/shared/interfaces';

interface EmailUserGroupNotification {
  title: string
  users: EmailNotificationUser[]
  all: boolean
}

interface EmailNotificationUser extends Partial<User> {
  lastNotification: { timestamp: Date, confirm: boolean }
  checked: boolean
}

@Component({
  selector: 'app-email-notification',
  templateUrl: './email-notification.component.html',
  styleUrls: ['./email-notification.component.scss']
})
export class EmailNotificationComponent implements OnInit {

  @Input()
  set callsheet(callsheet: CallSheet) {
    this.notifications = [
      {
        title: 'Clients',
        users: callsheet.clients.map(c => { return { ...c, checked: false } }),
        all: false
      },
      {
        title: 'Vendors',
        users: callsheet.vendors.map(v => { return { ...v, checked: false } }),
        all: false
      },
      {
        title: 'Crew',
        users: callsheet.crewList.map(c => { return { ...c, checked: false } }),
        all: false
      }
    ]
  }

  @Input() notifications: EmailUserGroupNotification[] = []
  @Output() onSendEmails = new EventEmitter<string[]>()
  hasChecked: boolean = false

  ngOnInit() { }

  onSelectAll(item: EmailUserGroupNotification) {
    for (const user of item.users) {
      user.checked = !item.all
    }
    this.validate(item)
  }

  validate(item: EmailUserGroupNotification) {
    const checkedCount = item.users.filter(x => x.checked).length
    item.all = checkedCount == item.users.length
    this.hasChecked = this.notifications.some(x => x.users.some(u => u.checked))
  }

  send() {
    const userIds = this.notifications.flatMap(x=> x.users.filter(f=> f.checked).map(u=> u._id!))
    this.onSendEmails.emit(userIds)
  }
}
