<div class="ap-container">
    <div class="row">
        <div class="offset-md-2 col-md-3 col-sm-6 d-flex justify-content-center flex-column gap-2 login">
            <div class="ap-pwc-logo"></div>
            <div>
                <h1>Welcome to Video Callsheet</h1>
                <span class="ap-mb-spacing-5">This site is designed for PwC's Video Team, enabling them to create the necessary call sheets for different video shoots. You can log in to add your required inputs or review the existing call sheets.</span>
            </div>
            <ap-button [btnType]="'primary'" [label]="'PwC SSO Login'" [type]="'button'" [compact]="false"
                (onClick)="onPwCSSOLogin($event)" [styleClass]="'login-btn'"></ap-button>
        </div>
    </div>
</div>