<div class="ap-container message">
    <div class="ap-pwc-logo"></div>
    <div class="row justify-content-center" *ngIf="(result$ | async); else loggedOut">
        <h3 class="col-12 col-md-7 col-sm-10">Thanks for your confirmation</h3>
        <p class="col-12 col-md-7 col-sm-10">Thank you for confirming the changes on our video callsheet. Your quick
            response and attention to detail are
            greatly appreciated. Your confirmation is essential in helping us stay updated on any potential changes that
            may occur, ensuring a seamless production process. </p>
    </div>
    <ng-template #loggedOut>
        <div class="row justify-content-center">
            <h3 class="col-12 col-md-7 col-sm-10">Oops!</h3>
            <p class="col-12 col-md-7 col-sm-10">It appears that this notification has already been confirmed
                previously. If it wasn't you who confirmed it, please reach out to our team or check the latest updates
                on the site to ensure you are aware of any relevant changes. We appreciate your attention and
                cooperation. </p>
        </div>
    </ng-template>
    <a href="/" class="ap-link">Back to homepage</a>
</div>