<ap-header [type]="'transparent'">
    <ng-template ngTemplate="title">
        Callsheets
    </ng-template>
</ap-header>
<div class="page-content ap-container" *ngIf="callsheet$ | async as callsheet">
    <div *ngIf="!isArchive" class="ap-py-spacing-7">
        <div class="row align-items-center">
            <div class="col-6">
                <h2 appWrapWithUnderscore class="ap-my-spacing-1 ap-py-spacing-1">{{callsheet.projectName}}</h2>
            </div>
            <div class="col-6 d-flex justify-content-end">
                <div class="ap-mr-spacing-8">
                    <p class="ap-my-spacing-1">JOB NUMBER</p>
                    <strong>#{{callsheet.referenceID}}</strong>
                </div>
                <div>
                    <p class="ap-my-spacing-1">SHOOT DATE</p>
                    <strong>{{callsheet.shootDate | date: "MM-dd-yyyy"}}</strong>
                </div>
            </div>
        </div>

        <hr class="hr dashed">

        <div class="row">

            <div class="col-12 col-sm-6 col-md-4">
                <app-time-panel [callSheet]="callsheet"></app-time-panel>
            </div>

            <div class="col-6 col-sm-6 col-md-2">
                <p>PRODUCERS</p>
                <div class="d-flex flex-column ap-my-spacing-4">
                    <strong>Lead Producer</strong>
                    <div *ngFor="let producer of callsheet.producers">
                        <ng-container *ngIf="producer.lead">
                            <p class="ap-my-spacing-1">{{producer.firstName}} {{producer.lastName}} <br>
                            </p>
                        </ng-container>
                    </div>
                    <strong class="ap-mt-spacing-5">Additional Producers</strong>
                    <div *ngFor="let producer of callsheet.producers">
                        <ng-container *ngIf="!producer.lead">
                            <p class="ap-my-spacing-1">{{producer.firstName}} {{producer.lastName}}</p>
                        </ng-container>
                    </div>
                </div>

            </div>

            <div class="col-6 col-sm-4 col-md-2">
                <p class="ap-mb-spacing-4">CLIENTS</p>
                <p>
                    <strong *ngFor="let client of callsheet.clients">{{client.firstName}} {{client.lastName}}
                        <br></strong>
                </p>
            </div>

            <div class="col-6 col-sm-4 col-md-2">
                <p class="ap-mb-spacing-4">VENUE CONTACT</p>
                <p class="ap-my-spacing-1"><strong>{{callsheet.location.contact.firstName}}
                        {{callsheet.location.contact.lastName}} </strong><br>
                </p>
            </div>

            <div class="col-6 col-sm-4 col-md-2">
                <p class="ap-mb-spacing-4">LOCATION</p>
                <strong>{{callsheet.location.name}}</strong>
                <p class="ap-my-spacing-1">
                    {{callsheet.location.firstStreet}}, {{callsheet.location.city}} <br>
                    {{callsheet.location.state}}, {{callsheet.location.zipCode}}
                </p>
                <strong>Location Security Office Responsible</strong>
                <p class="ap-my-spacing-1">{{callsheet.location.securityContact}}</p>
                <div *ngIf="callsheet.location.notes">
                    <strong>Notes</strong>
                    <p class="ap-my-spacing-1">{{callsheet.location.notes}}</p>
                </div>
            </div>

            <div *ngIf="callsheet.notes" class="row ap-mt-spacing-4">
                <div class="col-12">
                    <h3>Notes</h3>
                    <p>
                        {{callsheet.notes}}
                    </p>
                </div>
            </div>
        </div>

        <div *ngIf="callsheet.talents.length" class="row ap-my-spacing-4">
            <div class="d-flex justify-content-between">
                <h3>Talent</h3>
            </div>
            <app-panel [actions]="[]" class="col-md-4 col-sm-6" *ngFor="let talent of callsheet.talents">
                <app-business-card class="talentCard" [title]="talent.firstName + ' ' + talent.lastName"
                    [notes]="talent.talentNotes"
                    [image]="talent.headshot ? blobUrl + talent.headshot : '/assets/default-avatar.png'"></app-business-card>

            </app-panel>

        </div>

        <div class="row ap-my-spacing-4">
            <div class="col-12">
                <h3>Crew</h3>
                <ap-table #table [(originalData)]="callsheet.crewList" [condensed]="true" [striped]="true">
                    <table>
                        <thead>
                            <tr>
                                <th>
                                    <span>Name</span>
                                </th>
                                <th>
                                    <span>Arrival Time</span>
                                </th>
                                <th>
                                    <span>Notes</span>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let crew of table.data">
                                <td>
                                    {{crew.firstName}} {{crew.lastName}}
                                </td>
                                <td>
                                    {{crew.callTime | date: "hh:mm a" || 'TBA'}}
                                </td>
                                <td style="max-width: 20rem;">
                                    {{crew.crewNotes}}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </ap-table>
            </div>
        </div>

        <div class="row ap-my-spacing-4">
            <div class="col-12">
                <h3>Schedule</h3>
                <ap-table #schedule [(originalData)]="callsheet.schedule" [condensed]="true" [striped]="true">
                    <table>
                        <thead>
                            <tr>
                                <th>
                                    <span>Time</span>
                                </th>
                                <th>
                                    <span>Activity</span>
                                </th>
                                <th>
                                    <span>Notes</span>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of schedule.data">
                                <td>
                                    {{item.time | date: "hh:mm a" || 'TBA'}}
                                </td>
                                <td>
                                    {{item.activity}}
                                </td>
                                <td style="max-width: 20rem;">
                                    {{item.notes}}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </ap-table>
            </div>
        </div>
    </div>
</div>
<div *ngIf="isArchive" class="ap-p-spacing-8">
    <div class="row align-items-center">
        <div class="col-12">
            <p>This call sheet is archived, log in with your PwC account to gain access</p>
            <h3>Woops, your link has expired...</h3>
        </div>
        <div>
            <ap-button [btnType]="'primary'" [label]="'PwC SSO Login'" [type]="'button'" [compact]="false"
                (onClick)="onPwCSSOLogin($event)"></ap-button>
        </div>
    </div>
</div>