<ap-header [type]="'transparent'" [hidden]="embeded">
    <ng-template ngTemplate="title">
        Callsheets
    </ng-template>
    <ng-template ngTemplate="options">
        <a [href]="supportUrl" target="_blank" rel="noopener noreferrer">
            <ap-header-options-item [iconName]="'help-question-outline'" [label]="'Support'">
            </ap-header-options-item>
        </a>
    </ng-template>
    <ng-template ngTemplate="user">
        <ap-avatar [name]="(initials$ | async)!" borderWidth="0" diameter="40" [role]="'button'" [list]="avatarList" [withDropdown]="true">
            <ng-template ngTemplate="dropdownTemp">
                <p class="user-name">{{user.name}}</p>
                <hr class="hr profile">
                <ng-container *ngFor="let item of avatarList; let i = index;">
                    <ap-dropdown-list-item #dropdownListItem [item]="item" (onSelectItem)="onSelectItem($event)">
                        <ng-template *ngIf="item.iconName" ngTemplate="prefixTemp">
                            <span class="Appkit4-icon icon-{{item.iconName}}"></span>
                        </ng-template>
                    </ap-dropdown-list-item>
                </ng-container>
            </ng-template>
        </ap-avatar>
    </ng-template>
</ap-header>
<div class="page-body">
    <div class="page-sidebar">
        <app-sidebar [navList]="navList" [collapse]="isSidebarCollapsed" (onCollapseEvent)="isSidebarCollapsed = $event" [scroll]="false"></app-sidebar>
    </div>
    <div class="page-content ap-container" [class.collapsed]="isSidebarCollapsed">
        <router-outlet></router-outlet>
    </div>
</div>
<ap-footer [content]="footerContent" [type]="footerType" [hidden]="embeded"></ap-footer>

<ap-notification [id]="id" [showTimed]="false" [showExpandedIcon]="false" [animation]="true"
    [position]="position" [animation]="true" [closeable]="true" [maxNotificationsCount]="3"></ap-notification>