import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LandingLayoutComponent } from './layout/landing-layout/landing-layout.component';
import { LoginComponent } from './layout/login/login.component';
import { AuthGuard } from './core/guards/auth.guard';
import { ConfirmComponent } from './layout/confirm/confirm.component';
import { PublicPreviewComponent } from './layout/public-preview/public-preview.component';
import { HelpPageComponent } from './help/help-page.component';


const routes: Routes = [
  {
    path: 'workfront',
    children: [
      { path: '', loadChildren: () => import('./workfront/workfront.module').then(m => m.WorkfrontModule) }
    ]
  },
  {
    path: 'help',
    component: HelpPageComponent,
    children: [
      { path: '', loadChildren: () => import('./help/help.module').then(m => m.HelpModule) }
    ]
  },
  {
    path: 'login',
    component: LoginComponent,
  },
  {
    path: 'confirm/:id',
    component: ConfirmComponent,
  },
  {
    path: 'public/:id',
    component: PublicPreviewComponent,
  },
  {
    path: '',
    component: LandingLayoutComponent,
    canActivateChild: [AuthGuard],
    children: [
      { path: '', loadChildren: () => import('./main/main.module').then(m => m.MainModule) }
    ]
  },
  { path: '**', redirectTo: '/', }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled', anchorScrolling: 'enabled'})],
  exports: [RouterModule]
})

export class AppRoutingModule { }