<div class="col-12">
    <h3><strong>Details</strong></h3>
    <p class="ap-pb-spacing-4">Disclaimer: The set schedule is based on the shooting location local time. UTC{{timezone
        | number:'2.0' }}:00</p>
    <div class="row">
        <app-details *skeleton="loading;height: '40px';repeat: 9;className: 'col-4'" [callsheet]="(callsheet$ | async)!"
            (onDetailsChangeEvent)="onDetailsChange($event)"></app-details>
    </div>

    <hr class="hr" />

    <div class="row" *skeleton="loading;repeat: 3;">
        <div class="d-flex align-items-center justify-content-between ap-mb-spacing-5">
            <h3 class="ap-my-spacing-1"><strong>Talent</strong></h3>
            <div class="d-flex align-items-center">
                <span class="Appkit4-icon ap-ml-spacing-2" [class]="callsheetForm.includeTalentOnPrint ? 'icon-view-outline': 'icon-view-off-outline'" ap-tooltip [tooltipId]="'privacy'"
                    [tooltipContent]="callsheetForm.includeTalentOnPrint ? 'Email and phone of talents will be included in the printed version.' : 'Email and phone of talents will not be included in the printed version.'" [direction]="'left'"></span>
                <ap-toggle (onChange)="onTogglePrivacity($event, 'includeTalentOnPrint')"
                    [(ngModel)]="callsheetForm.includeTalentOnPrint" [showIndicator]="true">
                </ap-toggle>
            </div>
        </div>

        <app-generic-form-table [columns]="talentsTableConfig" [data]="callsheetForm.talents" [id]="'talents'"
            (onTableChangeEvent)="onTableChange($event,'talents')"></app-generic-form-table>
    </div>

    <hr class="hr" />

    <div class="row" *skeleton="loading;repeat: 3;">
        <div class="d-flex align-items-center justify-content-between ap-mb-spacing-5">
            <h3 class="ap-my-spacing-1"><strong>Clients</strong></h3>
            <div class="d-flex align-items-center">
                <span class="Appkit4-icon ap-ml-spacing-2" [class]="callsheetForm.includeClientOnPrint ? 'icon-view-outline': 'icon-view-off-outline'" ap-tooltip [tooltipId]="'privacy'"
                    [tooltipContent]="callsheetForm.includeClientOnPrint ? 'Email and phone of clients will be included in the printed version.' : 'Email and phone of clients will not be included in the printed version.'" [direction]="'left'"></span>
                <ap-toggle (onChange)="onTogglePrivacity($event, 'includeClientOnPrint')"
                    [(ngModel)]="callsheetForm.includeClientOnPrint" [showIndicator]="true">
                </ap-toggle>
            </div>
        </div>
        <app-generic-form-table [columns]="clientTableConfig" [data]="callsheetForm.clients" [id]="'clients'"
            (onTableChangeEvent)="onTableChange($event,'clients')"></app-generic-form-table>
    </div>

    <hr class="hr" />

    <div class="row" *skeleton="loading;repeat: 3;">
        <div class="d-flex align-items-center justify-content-between ap-mb-spacing-5">
            <h3 class="ap-my-spacing-1"><strong>Crew</strong></h3>
            <div class="d-flex align-items-center">
                <span class="Appkit4-icon ap-ml-spacing-2" [class]="callsheetForm.includeCrewOnPrint ? 'icon-view-outline': 'icon-view-off-outline'" ap-tooltip [tooltipId]="'privacy'"
                    [tooltipContent]="callsheetForm.includeCrewOnPrint ? 'Email and phone of crew members will be included in the printed version.' : 'Email and phone of crew members will not be included in the printed version.'" [direction]="'left'"></span>
                <ap-toggle (onChange)="onTogglePrivacity($event, 'includeCrewOnPrint')"
                    [(ngModel)]="callsheetForm.includeCrewOnPrint" [showIndicator]="true">
                </ap-toggle>
            </div>
        </div>
        <app-generic-form-table [columns]="crewTableConfig" [type]="'readonly'" [data]="callsheetForm.crewList"
            (onTableChangeEvent)="onTableChange($event,'crewList')"></app-generic-form-table>
        <div class="d-flex justify-content-end ap-mt-spacing-4">
            <ap-button *ngIf="showEnvoyInviteButton" (onClick)="modal.showModal('envoy-invites-modal')"
                [btnType]="'secondary'" [label]="'Envoy invites'" [disabled]="!enableNotification"></ap-button>
        </div>
    </div>

    <hr class="hr" />

    <div class="row">
        <h3><strong>Vendors</strong></h3>
        <app-generic-form-table [columns]="vendorsTableConfig" [data]="callsheetForm.vendors" [searchService]="'vendor'"
            (onTableChangeEvent)="onTableChange($event,'vendors')"
            *skeleton="loading;repeat: 3;"></app-generic-form-table>
    </div>

    <hr class="hr" />

    <div class="row">
        <h3><strong>Schedule</strong></h3>
        <app-generic-form-table [columns]="scheduleTableConfig" [data]="callsheetForm.schedule" [type]="'inline'" [customFormControls]="requiredOneScheduleInputControl"
            (onTableChangeEvent)="onTableChange($event,'schedule')"
            *skeleton="loading;repeat: 3;"></app-generic-form-table>
    </div>

    <hr class="hr" />

    <div *ngIf="(callsheet$ | async) as callsheet" class="ap-py-spacing-4 d-flex justify-content-end gap-1 sticky">
        <ap-button type="button" class="" (onClick)="modal.showModal('email-notification-modal')"
            [btnType]="'secondary'" [label]="'Notify'"
            [disabled]="!enableNotification || loading || (!callsheet.clients.length && !callsheet.vendors.length && !callsheet.crewList.length)"></ap-button>
        <ap-button type="button" (onClick)="submitForm()" [btnType]="'primary'" [label]="'Save'"
            [disabled]="loading"></ap-button>
    </div>
</div>

<ng-container *ngIf="(callsheet$ | async) as callsheet">
    <ap-modal #modal appModalId="envoy-invites-modal" [title]="'Envoy invites'" [style]="'width: 540px;'">
        <app-envoy-invites [crew]="callsheet.crewList" (onSendInvites)="sendEnvoyInvites($event)"></app-envoy-invites>
    </ap-modal>

    <ap-modal #modal appModalId="email-notification-modal" [title]="'Email notification'" [style]="'width: 540px;'">
        <app-email-notification [callsheet]="callsheet"
            (onSendEmails)="sendEmailNotifications($event)"></app-email-notification>
    </ap-modal>
</ng-container>