import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { OauthAdapterService } from 'src/app/core/oauth';
import { ApplicationUser } from 'src/app/shared/interfaces';
import { CustomNavigationItem } from 'src/app/shared/interfaces/custom-navigation-item.interface';
import { environment } from 'src/environments/env';

@Component({
  selector: 'app-landing-layout',
  templateUrl: './landing-layout.component.html',
  styleUrls: ['./landing-layout.component.scss']
})
export class LandingLayoutComponent {

  footerContent: string = "© 2023 PwC. All rights reserved. PwC refers to the PwC network and/or one or more of its member firms, each of which is a separate legal entity. Please see <a class='ap-link' href='https://www.pwc.com/structure'>www.pwc.com/structure</a> for further details.";
  footerType: string = 'text';
  embeded: boolean
  isSidebarCollapsed: boolean = false
  initials$: Observable<string>
  navList: Array<CustomNavigationItem> = []
  user!: ApplicationUser

  id: string = 'notification1'
  position: string = 'bottomRight'

  supportUrl: string = environment.supportUrl

  avatarList: any = [
    { value: 'help', label: 'Help', iconName: 'information-outline' },
    { value: 'logout', label: 'Logout', iconName: 'lockclosed-locked-outline' },
  ]

  pwcNav: Array<CustomNavigationItem> = [
    {
      name: 'Home',
      prefixIcon: 'home',
      route: ''
    },
    {
      name: 'Vendors',
      prefixIcon: 'truck',
      route: 'vendors'
    },
    {
      name: 'Locations',
      prefixIcon: 'location',
      route: 'location'
    },
    {
      name: 'Users',
      prefixIcon: 'audience',
      route: 'users'
    },
    {
      name: 'Archive',
      prefixIcon: 'folder-closed',
      route: 'archive'
    },
    // {
    //   name: 'Help',
    //   prefixIcon: 'help-question',
    //   route: '/help'
    // }
  ]

  vendorNav: Array<CustomNavigationItem> = [
    {
      name: 'Home',
      prefixIcon: 'home',
      route: ''
    },
    {
      name: 'Crew',
      prefixIcon: 'audience',
      route: '/users/crew'
    },
    // {
    //   name: 'Help',
    //   prefixIcon: 'help-question',
    //   route: '/help'
    // }
  ]

  constructor(private route: ActivatedRoute, private readonly authService: OauthAdapterService, private router: Router) {
    this.embeded = route.snapshot.queryParams['embeded']
    this.initials$ = this.authService.initials$
    this.isSidebarCollapsed = localStorage.getItem('sidebar-collapsed') === "true" || this.isSidebarCollapsed
  }

  ngOnInit(): void {
    this.authService.user$.subscribe((x: ApplicationUser) => {
      this.user = x
      this.navList = this.user.isPwC ? this.pwcNav : this.vendorNav
    })
  }

  onSelectItem($event: any) {
    switch ($event.selected.value) {
      case 'logout':
        this.authService.logout()
        break;
      case 'help':
      this.router.navigate(['/help'])
        break;

      default:
        break;
    }
  }
}
