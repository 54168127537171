<div class="ap-mb-spacing-7" *ngIf="callsheet$ | async as callsheet">
    <hr class="hr strong">
    <div class="row align-items-center">
        <div class="col-6">
            <h2 class="ap-my-spacing-1 ap-py-spacing-1">{{callsheet.projectName}}</h2>
        </div>
        <div class="col-6 d-flex justify-content-end">
            <div class="ap-mr-spacing-8">
                <p class="ap-my-spacing-1">JOB NUMBER</p>
                <strong>#{{callsheet.referenceID}}</strong>
            </div>
            <div>
                <p class="ap-my-spacing-1">SHOOT DATE</p>
                <strong>{{callsheet.shootDate | date: "MM-dd-yyyy"}}</strong>
            </div>
        </div>
    </div>

    <hr class="hr dashed">

    <div class="row">
        <!-- PANEL -->
        <div class="col-12 col-sm-6 col-md-4">
            <app-time-panel [callSheet]="callsheet"></app-time-panel>
        </div>
        <!-- PRODUCERS -->
        <div class="col-6 col-sm-6 col-md-2">
            <p>PRODUCERS</p>
            <div class="d-flex flex-column ap-my-spacing-4">
                <strong>Lead Producer</strong>
                <div *ngFor="let producer of callsheet.producers">
                    <ng-container *ngIf="producer.lead">
                        <p class="ap-my-spacing-1">{{producer.firstName}} {{producer.lastName}} <br>
                            {{producer.email}}<br>
                            {{producer.phone}}
                        </p>
                    </ng-container>
                </div>
                <strong class="ap-mt-spacing-5">Additional Producers</strong>
                <div *ngFor="let producer of callsheet.producers">
                    <ng-container *ngIf="!producer.lead">
                        <p class="ap-my-spacing-1">{{producer.firstName}} {{producer.lastName}}</p>
                    </ng-container>
                </div>
            </div>

        </div>
        <!-- VENUE CONTACT -->
        <div class="col-6 col-sm-4 col-md-2">
            <p class="ap-mb-spacing-4">VENUE CONTACT</p>
            <p class="ap-my-spacing-1"><strong>{{callsheet.location.contact.firstName}}
                    {{callsheet.location.contact.lastName}} </strong><br>
                {{callsheet.location.contact.email}} <br>
                {{callsheet.location.contact.phone}}
            </p>
        </div>
        <!-- LOCATION -->
        <div class="col-6 col-sm-4 col-md-2">
            <p class="ap-mb-spacing-4">LOCATION</p>
            <strong>{{callsheet.location.name}}</strong>
            <p class="ap-my-spacing-1">
                {{callsheet.location.firstStreet}}, {{callsheet.location.city}} <br>
                {{callsheet.location.state}}, {{callsheet.location.zipCode}}
            </p>
            <strong>Location Security Office Responsible</strong>
            <p class="ap-my-spacing-1">{{callsheet.location.securityContact}}</p>
            <ng-container *ngIf="callsheet.location.notes">
                <strong>Notes</strong>
                <p class="ap-my-spacing-1">{{callsheet.location.notes}}</p>
            </ng-container>

        </div>
    </div>

    <hr class="hr" />

    <div class="row ap-my-spacing-2">
        <p *ngIf="callsheet.vendors[0].role.length" class="ap-pt-spacing-2"><strong>Requested crew roles: </strong>{{callsheet.vendors[0].role.join(', ') || 'TBA'}}</p>
        <p *ngIf="requirements"><strong>Notes: </strong>{{requirements}}</p>
    </div>

    <hr class="hr" />

    <div class="col-12" *ngIf="callsheetForm">
        <form [formGroup]="callsheetForm">
            <div class="row">
                <h3><strong>Crew</strong></h3>
                <app-generic-form-table [searchService]="'employees'" [columns]="crewTableConfig" [data]="callsheetForm.value.crewList"
                    (onTableChangeEvent)="onCallsheetChange($event,'crewList')" *skeleton="loading;repeat: 3;"></app-generic-form-table>
            </div>
    
            <div class="row ap-py-spacing-4 sticky">
                <ap-button type="button" class="d-flex justify-content-end" (click)="submitForm()" [btnType]="'primary'"
                    [label]="'Save'" [disabled]="!callsheetForm.valid"></ap-button>
            </div>
        </form>
    </div>
    
</div>

