import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { catchError, Subject, map, scan, shareReplay, tap, throwError } from "rxjs";
import { environment } from "src/environments/env";
import { Vendor, VendorEmployee } from "src/app/main/vendors/vendors.interface";
import { CustomNotificationService } from "src/app/shared/services/notification.service";

interface VendorStateAction {
    action: "init" | "add" | "remove" | "update"
    payload: Vendor
}

@Injectable({
    providedIn: 'root'
})
export class VendorsService {

    private vendors: Vendor[] = []
    private action$ = new Subject<VendorStateAction>()

    private vendors$ = this.action$.pipe(
        scan((state: Vendor[], action: VendorStateAction) => {
            switch (action.action) {
                case "init":
                    return this.vendors
                case "add":
                    return [...state, action.payload]
                case "remove":
                    return state.filter((vendor: Vendor) => vendor._id !== action.payload._id)
                case "update":
                    const index = state.findIndex((vendor: Vendor) => vendor._id === action.payload._id);
                    if (index !== -1) {
                        const newState = [...state];
                        newState[index] = action.payload;
                        return newState;
                    }
                    return state;
                default:
                    return state
            }
        }, []),
        shareReplay(1),
    )

    constructor(private httpService: HttpClient, private readonly notificationService: CustomNotificationService) { }

    getVendors() {
        if (!this.vendors.length) {
            this.httpService.get<Vendor[]>(`${environment.apiUrl}/vendors`)
                .pipe(
                    tap((vendors) => {
                        this.vendors = vendors
                        this.action$.next({ action: 'init', payload: {} as Vendor })
                    })
                ).subscribe()
        }
        return this.vendors$
    }

    create(vendor: Vendor) {
        const action: VendorStateAction = {
            action: "add",
            payload: vendor
        }
        this.httpService.post<Vendor>(`${environment.apiUrl}/vendors/`, action.payload)
            .pipe(
                tap(x => this.notificationService.success('Vendor created sucessfully.')),
                map(x => this.action$.next({ ...action, payload: x })),
                catchError(x => {
                    this.notificationService.error('Vendor was not created.')
                    return throwError(() => x)
                })
            ).subscribe()
    }

    update(vendor: Vendor) {
        const action: VendorStateAction = {
            action: "update",
            payload: vendor
        }
        this.httpService.put<Vendor>(`${environment.apiUrl}/vendors/${action.payload._id}`, action.payload)
            .pipe(
                tap(x => this.notificationService.success('Vendor updated sucessfully.')),
                map(x => this.action$.next(action)),
                catchError(x => {
                    this.notificationService.error('Vendor was not updated.')
                    return throwError(() => x)
                })
            ).subscribe()
    }

    delete(vendor: Vendor) {
        const action: VendorStateAction = {
            action: "remove",
            payload: vendor
        }
        this.httpService.delete<Vendor>(`${environment.apiUrl}/vendors/${action.payload._id}`)
            .pipe(
                tap(x => this.notificationService.success('Vendor deleted sucessfully.')),
                map(x => this.action$.next(action)),
                catchError(x => {
                    this.notificationService.error('Vendor was not deleted.')
                    return throwError(() => x)
                })
            ).subscribe()
    }

    search(query: string) {
        return this.httpService.post<Vendor[]>(`${environment.apiUrl}/vendors/search`, { query })
    }

    getEmployees() {
        return this.httpService.get<{ _id: string, employees: VendorEmployee[] }>(`${environment.apiUrl}/vendors/employees`)
    }

    addEmployee(payload: any) {
        return this.httpService.post<VendorEmployee>(`${environment.apiUrl}/vendors/employees`, payload)
    }
}