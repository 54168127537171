import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ViewChild } from '@angular/core';

@Component({
  selector: 'app-confirm-modal',
  templateUrl: './confirm-modal.component.html',
  styleUrls: ['./confirm-modal.component.scss']
})
export class ConfirmModalComponent {

  style: string = 'width: 540px;';
  footerStyle: string = 'padding-top: 8px; margin-top: -8px; min-height: 64px;';

  @ViewChild('confirmModal', { static: false }) modal: any;

  @Input() confirmLabel: string = 'Ok'
  @Input() description: string = ''
  @Input() title: string = ''
  @Input() id: string = 'confirm-modal'
  @Output() onConfirmEvent = new EventEmitter<string>();

  show() {
    this.modal.showModal(this.id);
  }

  confirm() {
    this.onConfirmEvent.emit()
    this.modal.closeModal(this.id);
  }

  cancel() {
    this.modal.closeModal(this.id);
  }
}
