import { Component } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { CallsheetsService } from 'src/app/main/callsheets/callsheets.service';
import { UsersService } from 'src/app/main/users/users.service';
import { CallSheet, Crew } from '../../../../shared/interfaces';
import { ActivatedRoute } from '@angular/router';
import { Observable, catchError, map } from 'rxjs';
import { GenericFormTableColumn } from '../../shared/components/generic-form-table/generic-form-table.component';

@Component({
  selector: 'app-vendors-form',
  templateUrl: './vendors-form.component.html',
  styleUrls: ['./vendors-form.component.scss']
})
export class VendorsFormComponent {
  constructor(
    private readonly usersService: UsersService,
    private readonly callsheetsService: CallsheetsService,
    private readonly route: ActivatedRoute,
    private fb: FormBuilder
  ) { }

  callsheetForm!: FormGroup
  loading: boolean = true
  callsheet$!: Observable<CallSheet>
  requirements: string = ''
  callSheetId: string = ''

  crewTableConfig: GenericFormTableColumn[] = [
    {
      value: 'Name',
      key: 'firstName',
      type: 'avatar',
    },
    {
      value: 'Phone',
      key: 'phone',
      type: 'text'
    },
    {
      value: 'Email',
      key: 'email',
      type: 'text'
    },
    {
      value: 'Job Title',
      key: 'jobTitle',
      type: 'roleDropdown',
      editable: true,
      placeholder: 'Job Title'
    },
    {
      value: 'Notes',
      key: 'crewNotes',
      type: 'text',
      placeholder: 'Notes',
      editable: true
    }
  ]


  ngOnInit() {
    this.callSheetId = this.route.snapshot.params['id']
    this.callsheet$ = this.callsheetsService.getById(this.callSheetId).pipe(
      catchError(err => {
        //this.router.navigate([''])
        throw err
      }),
      map((c: CallSheet) => {
        this.callsheetForm = this.fb.group({
          crewList: this.fb.array(c.crewList),
        })
        this.requirements = c.vendors && c.vendors[0].requirements || ''
        this.loading = false
        return c
      }))
  }

  onCallsheetChange(value: any, prop: string) {
    const control = this.callsheetForm.get(prop)!
    if (control instanceof FormArray) {
      control.clear()
      for (const item of value) {
        let formGroup = new FormGroup({})
        Object.keys(item).forEach((key) => {
          formGroup.addControl(key, new FormControl(item[key]))
        })
        control.push(formGroup)
      }
    } else {
      control.setValue(value)
    }
  }

  submitForm() {
    const paylaod = {
      _id: this.callSheetId,
      crewList: this.callsheetForm.value.crewList.map((x: Crew)=>{
        const { isProducer: _, ...newObj } = x;
        return newObj
      })
    }
    this.loading = true
    this.callsheetsService.updateCrew(paylaod)
  }
}
