import { Component, OnInit } from '@angular/core';
import { Observable, switchMap, tap } from 'rxjs';
import { CallSheet, ShootingLocation, Talent } from 'src/app/shared/interfaces';
import { CallsheetsService } from '../../../callsheets.service';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/env';
import { BusinessCardItem } from 'src/app/shared/components/business-card/business-card.component';

@Component({
  selector: 'app-section-selector',
  templateUrl: './section-selector.component.html',
  styleUrls: ['./section-selector.component.scss']
})
export class SectionSelectorComponent implements OnInit {

  constructor(private callsheetsService: CallsheetsService, private route: ActivatedRoute, private router: Router) { }


  callsheet$!: Observable<CallSheet>
  sourceCallsheet!: CallSheet;
  blobUrl: string = environment.assetsUrl

  detailsSection: boolean = true;
  talentsSection: boolean = true;
  clientsSection: boolean = true;
  vendorsSection: boolean = true;
  crewSection: boolean = true;
  scheduleSection: boolean = true;


  async ngOnInit() {
    this.callsheet$ = this.route.params.pipe(
      switchMap((params: any) => {
        return this.callsheetsService.getById(params.id).pipe(
          tap((result: CallSheet) => {
            this.sourceCallsheet = result;
          })
        );
      })
    );
  }

  getItems(talent: Talent): BusinessCardItem[] {
    return [
      { icon: 'Appkit4-icon icon-email-fill', content: talent.email, link: false },
      { icon: 'Appkit4-icon icon-call-fill', content: talent.phone, link: false },
    ]
  }

  selectVendorsSection(event: any) {
    if (!event.switchState) {
      this.crewSection = false;
    }
  }

  cloneSelected() {
    let payload = { ...this.sourceCallsheet };

    if (!this.detailsSection) {
      payload = {
        ...payload,
        producers: [],
        notes: '',
      };
    }
    if (!this.talentsSection) {
      payload = {
        ...payload,
        talents: [],
      };
    }
    if (!this.clientsSection) {
      payload = {
        ...payload,
        clients: [],
      };
    }
    if (!this.vendorsSection) {
      payload = {
        ...payload,
        vendors: [],
      };
    }
    if (!this.crewSection) {
      payload = {
        ...payload,
        crewList: [],
      };
    }
    if (!this.scheduleSection) {
      payload = {
        ...payload,
        schedule: [],
      };
    }

    this.callsheetsService.update(payload).subscribe(x => {
      this.router.navigate(['/callsheet/', this.sourceCallsheet._id!]);
    })
  }

}
