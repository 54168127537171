import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject, catchError, map, of, scan, shareReplay, startWith, tap, throwError } from 'rxjs';
import { ShootingLocation } from 'src/app/shared/interfaces/callsheet.interface';
import { environment } from 'src/environments/env';
import { CustomNotificationService } from 'src/app/shared/services/notification.service';

interface LocationStateAction {
  action: "init" | "add" | "remove" | "update"
  payload: ShootingLocation
}

@Injectable({
  providedIn: 'root'
})

export class LocationsService {

  private locations: ShootingLocation[] = []
  private action$ = new Subject<LocationStateAction>()

  locations$ = this.action$.pipe(
    startWith({ action: "init", payload: {} as ShootingLocation } as LocationStateAction),
    scan((state: ShootingLocation[], action: LocationStateAction) => {
      switch (action.action) {
        case "init":
          return this.locations
        case "add":
          return [...state, action.payload]
        case "remove":
          return state.filter((location: ShootingLocation) => location._id !== action.payload._id)
        case "update":
          const index = state.findIndex((location: ShootingLocation) => location._id === action.payload._id);
          if (index !== -1) {
            const newState = [...state];
            newState[index] = action.payload;
            return newState;
          }
          return state;
        default:
          return state
      }
    }, []),
    shareReplay(1)
  )

  constructor(private httpService: HttpClient, private readonly notificationService: CustomNotificationService) {
    this.httpService.get<ShootingLocation[]>(`${environment.apiUrl}/locations`)
      .pipe(
        map((locations) => {
          this.locations = locations
          this.action$.next({ action: 'init', payload: {} as ShootingLocation })
        })
      ).subscribe()
  }

  create(location: ShootingLocation) {
    const action: LocationStateAction = {
      action: "add",
      payload: location
    }

    this.httpService.post<ShootingLocation>(`${environment.apiUrl}/locations/`, action.payload)
      .pipe(
        tap(x => this.notificationService.success('Location created sucessfully.')),
        map(x => this.action$.next({ ...action, payload: x })),
        catchError(x => {
          this.notificationService.error('Location was not created.')
          return throwError(() => x)
        })
      ).subscribe()
  }

  update(location: ShootingLocation) {
    const action: LocationStateAction = {
      action: "update",
      payload: location
    }
    this.httpService.put<ShootingLocation>(`${environment.apiUrl}/locations/${action.payload._id}`, action.payload)
      .pipe(
        tap(x => this.notificationService.success('Location updated sucessfully.')),
        map(x => this.action$.next(action)),
        catchError(x => {
          this.notificationService.error('Location was not updated.')
          return throwError(() => x)
        })
      ).subscribe()
  }

  delete(location: ShootingLocation) {
    const action: LocationStateAction = {
      action: "remove",
      payload: location
    }
    this.httpService.delete<ShootingLocation>(`${environment.apiUrl}/locations/${action.payload._id}`)
      .pipe(
        tap(x => this.notificationService.success('Location deleted sucessfully.')),
        map(x => this.action$.next(action)),
        catchError(x => {
          this.notificationService.error('Location was not deleted.')
          return throwError(() => x)
        })
      ).subscribe()
  }

}
