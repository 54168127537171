import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { User } from '../../../../../shared/interfaces/callsheet.interface';
import { environment } from 'src/environments/env';
import { CustomNotificationService } from '../../../../../shared/services/notification.service';
import { UserFormModalComponent } from 'src/app/shared/components/user-form-modal/user-form-modal.component';

export interface GenericFormTableColumn {
  value: string,
  key: string,
  type: 'timepicker' | 'avatar' | 'text' | 'roleDropdown',
  hidden?: boolean
  placeholder?: string,
  editable?: boolean,
  values?: any[]
  required?: boolean
}

@Component({
  selector: 'app-generic-form-table',
  templateUrl: './generic-form-table.component.html',
  styleUrls: ['./generic-form-table.component.scss']
})

export class GenericFormTableComponent implements OnInit {

  @Input()
  columns: GenericFormTableColumn[] = []

  @Input()
  data: any = []

  @Input()
  avatarDiameter: string = '45'

  @Input()
  id!: string

  @Input()
  searchService: 'user' | 'vendor' | 'employees' = 'user'

  @Input()
  type: 'inline' | 'search' | 'readonly' = 'search'

  @Input()
  customFormControls: any

  @Output()
  onTableChangeEvent = new EventEmitter<any[]>()

  @ViewChild(UserFormModalComponent) userModal!: UserFormModalComponent

  inlineForm!: FormGroup

  blobUrl: string = environment.assetsUrl
  tableData = this.data

  constructor(private readonly notificationService: CustomNotificationService) { }

  ngOnInit() {
    if (this.type === 'inline') {
      this.inlineForm = new FormGroup(this.columns.reduce((obj: any, column: GenericFormTableColumn) => {
        obj = {
          ...obj,
          [column.key]: new FormControl('', column.required ? Validators.required : null)
        }
        return obj;
      }, {}), this.customFormControls())
    }
  }

  add(item: any) {
    if (item.email) {
      const index = this.tableData.findIndex((x: User) => x.email === item.email)
      if (index != -1) {
        this.notificationService.warning('User already added.')
        return
      }
    }

    this.tableData.push(item)
    this.onTableChangeEvent.emit(this.tableData)

    if (this.type == 'inline') {
      this.inlineForm.reset()
    }
  }

  delete(index: number) {
    this.tableData = this.tableData.filter((x: any, i: number) => i !== index)
    this.data = this.data.filter((x: any, i: number) => i !== index)
    this.onTableChangeEvent.emit(this.tableData)
  }

  onSearch(item: User) {
    this.add(this.mapUser(item))
  }

  onTableChange($event: any, key: string, index: number) {
    this.tableData[index][key] = $event
    this.onTableChangeEvent.emit(this.tableData)
  }

  mapUser(user: User) {
    return {
      ...this.columns.reduce((obj: any, col: GenericFormTableColumn) => {
        if (col.key) {
          obj[col.key] = '';
        }
        return obj;
      }, {}),
      ...user
    }
  }

  trackId(index: number, item: any) {
    return item.index
  }

  onUserSubmit(user: User) {
    const { isProducer: _, ...newObj } = user
    const index: number = this.tableData.findIndex((x: User) => x.email == newObj.email)

    if (index === -1) {
      this.tableData.push(this.mapUser(user))
    } else {
      this.tableData[index] = { ...this.tableData[index], ...newObj }
    }

    this.onTableChangeEvent.emit(this.tableData)
  }

  showUserModal(user: User) {
    this.userModal.show(user || {})
  }
}
