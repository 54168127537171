import { Injectable } from "@angular/core"
import { Router, ActivatedRouteSnapshot } from "@angular/router"
import { Observable } from "rxjs"
import { map } from "rxjs/operators"
import { ApplicationUser } from 'src/app/shared/interfaces';
import { OauthAdapterService } from "../oauth"

@Injectable({
    providedIn: "root",
})
export class PwCGuard {
    constructor(private readonly router: Router, private readonly authService: OauthAdapterService) { }

    canActivate(
        route: ActivatedRouteSnapshot
    ): Observable<boolean> | boolean {
        const allowedRoles = !!route.data["vendor"]
        return this.authService.user$.pipe(
            map((user: ApplicationUser) => {
                if (!user.isPwC) {
                    this.router.navigate(["/"])
                }
                return true
            })
        )
    }
}