<div class="list-container">
    <div class="list-container-items d-flex align-items-center justify-content-between ap-bg-neutral-01">
        <input type="text" readonly [placeholder]="placeholder" (click)="expand = true" [value]="inputStr" [ngClass]="{'showBorder': showBorder}">
        <span class="badge-item" [hidden]="selectedRoles.length < itemsToShow + 1">+{{selectedRoles.length - itemsToShow}} more</span>
    </div>
    <ap-list [bordered]="true" *ngIf="expand">
        <input type="text" [placeholder]="'Search'" [formControl]="searchInput">
        <ap-list-item *ngFor="let item of (filterRoles$ | async)" [attr.role]="'option'">
            <ap-list-item-meta-avatar>
                <ap-checkbox [(ngModel)]="item.checked" (onChange)="onListChange()">{{item.value}}</ap-checkbox>
            </ap-list-item-meta-avatar>
        </ap-list-item>
        <ap-list-item *ngIf="noFiltersFound" class="notFound">
            <i class="Appkit4-icon icon-search-not-found-outline"></i>
            No role found
        </ap-list-item>
    </ap-list>
</div>

<div [hidden]="!expand" class="custom-backdrop" (click)="expand = false"></div>

