import { Component, Input } from '@angular/core';
import { User } from '../../interfaces';
import { environment } from 'src/environments/env';

@Component({
  selector: 'app-user-info',
  templateUrl: './user-info.component.html',
  styleUrls: ['./user-info.component.scss']
})
export class UserInfoComponent {

  @Input() user!: Partial<User>
  blobUrl: string = environment.assetsUrl
}

