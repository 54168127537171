import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { UpcomingCallsheetComponent } from './components/upcoming-callsheet/upcoming-callsheet.component';
import { TableModule } from '@appkit4/angular-components/table';
import { SharedModule } from 'src/app/shared/shared.module';
import { EmailNotificationComponent } from './components/modals/email-notification/email-notification.component';
import { EnvoyInvitesComponent } from './components/modals/envoy-invites/envoy-invites.component';
import { DetailsComponent } from './components/video-team-form/details/details.component';
import { VideoTeamFormComponent } from './components/video-team-form/video-team-form.component';
import { ListModule } from '@appkit4/angular-components/list';
import { TabsModule } from '@appkit4/angular-components/tabs';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TimepickerModule } from '@appkit4/angular-components/timepicker';
import { SearchModule } from '@appkit4/angular-components/search';
import { DropdownListItemModule } from '@appkit4/angular-components/dropdown-list-item';
import { GenericFormTableComponent } from './components/generic-form-table/generic-form-table.component';
import { SearchFieldComponent } from './components/generic-form-table/custom-fields/search-field/search-field.component';
import { PaginationModule } from '@appkit4/angular-components/pagination';
import { CallsheetsTableComponent } from '../components/callsheets-table/callsheets-table.component';
import { ToggleModule } from '@appkit4/angular-components/toggle';
import { TimePanelComponent } from '../components/time-panel/time-panel.component';

@NgModule({
  declarations: [
    UpcomingCallsheetComponent,
    EmailNotificationComponent,
    EnvoyInvitesComponent,
    DetailsComponent,
    VideoTeamFormComponent,
    GenericFormTableComponent,
    SearchFieldComponent,
    CallsheetsTableComponent,
    TimePanelComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    TableModule,
    ListModule,
    FormsModule,
    TimepickerModule,
    SearchModule,
    ReactiveFormsModule,
    TabsModule,
    DropdownListItemModule,
    PaginationModule,
    ToggleModule
  ],
  exports: [
    UpcomingCallsheetComponent,
    DetailsComponent,
    VideoTeamFormComponent,
    EmailNotificationComponent,
    EnvoyInvitesComponent,
    GenericFormTableComponent,
    CallsheetsTableComponent,
    TimePanelComponent
  ],
  providers: [],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class CallsheetSharedModule { }
