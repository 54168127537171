import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ActionButton } from '../../interfaces/actions.interface';

@Component({
  selector: 'app-header-page',
  templateUrl: './header-page.component.html',
  styleUrls: ['./header-page.component.scss']
})

export class HeaderPageComponent {

  @Output() onActionEvent = new EventEmitter<string>()

  @Input()
  title: string = ''

  @Input()
  btnList: ActionButton[] = []

  onClick(action: string) {
    this.onActionEvent.emit(action);
  }
}
