<div class="d-flex flex-column">
    <div class="d-flex align-items-center justify-content-center flex-column">
        <img src="{{image}}" *ngIf="image" alt="Card image" class="ap-mt-spacing-5">
        <h2>{{ title }}</h2>
    </div>
    <div class="d-flex flex-column gap-1 ap-pb-spacing-4 ap-mb-spacing-3 badges">
        <div class="d-flex align-items-center ap-px-spacing-4 ap-py-spacing-2 badge"
            *ngFor="let item of items; trackBy: trackId">
            <i class="Appkit4-icon {{ item.icon }} ap-mr-spacing-3"></i>
            <span *ngIf="!item.link">{{item.content || 'N/A'}}</span>
            <a *ngIf="item.link" [href]="item.content" target="_blank" class="ap-link">{{item.content}}</a>
        </div>
        <span class="ap-font-14 ap-pt-spacing-4 ap-mt-spacing-2 description" [hidden]="!description">{{ description
            }}</span>
        <span class="ap-font-14 ap-pt-spacing-4 ap-mt-spacing-2 description"
            [hidden]="!notes"><strong>Notes</strong><br>
            {{notes}}</span>
    </div>
</div>