import { Component, ElementRef } from '@angular/core';

@Component({
  selector: 'skeleton-rect',
  host: {
    'class': 'skeleton'
  },
  template: `<div class="pulse"></div>`,
  styleUrls: ['./rect.component.scss']
})
export class RectComponent {
  width!: string;
  height!: string;
  className!: string;

  constructor(private host: ElementRef<HTMLElement>) { }

  ngOnInit() {
    const host = this.host.nativeElement;

    if (this.className) {
      this.className.split(" ").forEach(x=> host.classList.add(x))
    }

    host.querySelector('div')!.style.setProperty('width', this.width ?? '100%');
    host.querySelector('div')!.style.setProperty('height', this.height ?? '30px');
  }
}
