<div class="ap-container ap-px-spacing-1 envoy-modal">
    <ap-tabset [type]="'underline'" [(activeIndex)]="activeIndex" [overflow]="false" [styleClass]="'custom-tab'">
        <ap-tab [label]="'Pending (' + pending.length + ')'" [disabled]="false" class="tab-content">
            <div *ngFor="let crew of pending;let i = index;"
                class="border-row d-flex justify-content-between align-items-center ap-px-spacing-3 ap-py-spacing-4">
                <app-user-info [user]="crew"></app-user-info>
                <ap-checkbox [tabindex]="-1" [(ngModel)]="crew.checked" (onChange)="validate()"></ap-checkbox>
            </div>
            <div class="d-flex justify-content-center ap-mt-spacing-8" *ngIf="!pending.length">There are no pending invitations to send.</div>
        </ap-tab>
        <ap-tab [label]="'Invited (' + active.length + ')'" [disabled]="false">
            <div *ngFor="let crew of active;let i = index;"
                class="border-row d-flex justify-content-between align-items-center ap-my-spacing-5 ap-pb-spacing-5">
                <app-user-info [user]="crew"></app-user-info>
                <a [href]="envoyInviteUrl + crew.envoyInviteId" target="_blank" class="ap-link"><i
                        class="Appkit4-icon icon-open-in-new-window-outline"></i>Envoy</a>
            </div>
            <div class="d-flex justify-content-center ap-mt-spacing-8" *ngIf="!active.length">No invitations have been sent yet.</div>
        </ap-tab>
    </ap-tabset>
    <div class="d-flex justify-content-end ap-mt-spacing-4">
        <ap-button (onClick)="sendEnvoyInvites()" [icon]="'paper-airplane-outline'" [disabled]="!hasChecked"
            [btnType]="'primary'" [label]="'Send invites'"></ap-button>
    </div>
</div>