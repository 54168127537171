import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Crew } from 'src/app/shared/interfaces';
import { environment } from 'src/environments/env';

type PendingCrew = Crew & { checked: boolean };

@Component({
  selector: 'app-envoy-invites',
  templateUrl: './envoy-invites.component.html',
  styleUrls: ['./envoy-invites.component.scss']
})
export class EnvoyInvitesComponent implements OnInit {

  @Input() 
  set crew(crew: Crew[]){
    this.pending = crew.filter((x: Crew) => !x.envoyInviteId).map(x => { return {...x, checked: false}})
    this.active = crew.filter((x: Crew) => x.envoyInviteId)
  }

  @Output() onSendInvites = new EventEmitter<string[]>()

  envoyInviteUrl: string = environment.envoyInviteUrl
  activeIndex: number = 0
  hasChecked: boolean = false
  pending: PendingCrew[] = []
  active: Crew[] = []

  ngOnInit() {}

  validate() {
    this.hasChecked = this.pending.some(x => x.checked)
  }

  sendEnvoyInvites() {
    this.onSendInvites.emit(this.pending.filter(x => x.checked).map(x=> x._id))
  }
}
