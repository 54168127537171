<div class="d-flex flex-column gap-3 ap-p-spacing-5 panel">
    <div class="ap-px-spacing-3 badge">
        <ap-badge value="CREW CALL" type="success"></ap-badge>
    </div>
    <div class="d-flex align-items-baseline crewCall">
        <p><strong>{{(callSheet.shootDate | date: "hh:mm") || ''}}</strong></p>
        <p class="ap-ml-spacing-3 period"><strong> {{(callSheet.shootDate | date: "a") || 'TBA'}}</strong></p>
    </div>
    <div class="d-flex justify-content-between">
        <div>
            <p class="ap-mb-spacing-2">TALENT CALL</p>
            <p class="callTime"><strong>{{(callSheet.talentCall | date: "hh:mma") || 'TBA'}}</strong></p>
        </div>
        <div>
            <p class="ap-mb-spacing-2">SHOOTING TIME</p>
            <p class="callTime"><strong>{{(callSheet.filmingStart | date: "hh:mma") || 'TBA'}}</strong></p>
        </div>
    </div>
</div>