<div class="row">
    <div class="col-8">
        <h3>Clone and import features</h3>
        <p>
            The clone and import features are design to save the user time, reuse call sheets information and avoid the
            repetitive task of filling the same project details all over again.
        </p>

        <p>
            Both active and archive call sheets, can be “cloned” and reused. You'll need to select a Workfront project,
            input a new shooting date and location, and either "clone the whole call sheet" to retain all previous
            information or "clone by section" to select specific data and discard the rest.

        </p>


        <p>
            Look for the clone button inside the three dots dropdown on active call sheets or from the archived list.

        </p>

        <p>
            The application will generate a new document with the selected information already filled-in. 

        </p>

        <p>
            The import feature works in a similar way, it allows users to import information from an existing call sheet
            when creating a new document, this way you won’t be starting from scratch. 

        </p>
        <p>
            Do this from Workfront, when creating a new document. Locate the call sheet you want to import, either
            active or archived. Import all information or import by section, just as with the clone feature. The
            selected sections will be automatically populated. You can always make changes later.

        </p>
        <p>

            Please watch the following video for a walkthrough of the mentioned functionalities </p>
            <div>
                <video controls class="w-100">
                    <source src="../../../assets/video-tutorials/clone_import.mov" type="video/mp4" />
    
                    Your browser does not support the video tag.
                </video>
            </div>

    </div>

</div>