import { Component } from '@angular/core';
import { BehaviorSubject, Observable, debounceTime, distinctUntilChanged, map, merge, of, skip, switchMap, tap } from 'rxjs';
import { ActionButton, CallSheet } from 'src/app/shared/interfaces';
import { CallsheetsService } from '../../callsheets.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-archive-callsheet',
  templateUrl: './archive.component.html',
  styleUrls: ['./archive.component.scss']
})
export class ArchiveCallsheetComponent {

  itemsPerPage = 15
  feature = 0
  totalPages$: Observable<number>
  page$ = new BehaviorSubject<number>(1)
  callsheets$!: Observable<CallSheet[]>
  searchText$ = new BehaviorSubject<string>('')
  showPages$: Observable<number>
  selectedPage:number = 1

  actions: ActionButton[] = [
    {
      icon: 'Appkit4-icon icon-view-outline',
      action: 'preview',
      label: 'Preview',
      type: ''
    },
    {
      icon: 'Appkit4-icon icon-duplicate-outline',
      action: 'clone',
      label: 'Clone',
      type: ''
    }
  ]
  constructor(private callsheetsService: CallsheetsService, private router: Router) {
    this.totalPages$ = this.callsheetsService.totalArchived$.pipe(
      map(totalArchived => !!totalArchived ? Math.ceil((totalArchived - this.feature) / this.itemsPerPage) : 0)
    );

    this.showPages$ = this.totalPages$;
  }


  ngOnInit() {
    const combined$ = this.page$.pipe(
      distinctUntilChanged(),
      switchMap((page: number) =>
        this.searchText$.pipe(
          distinctUntilChanged(),
          debounceTime(500),
          switchMap((text: string) => {
            const limit = page === 1 ? this.itemsPerPage + this.feature : this.itemsPerPage;
            const searchParams = {
              archive: true,
              sort: -1,
              ...(text && { text }),
              ...((page !== 1 && this.feature) && { skip: this.feature }),
              limit,
              page: page - 1
            };

            const filteredCallsheets = this.callsheetsService.search(searchParams, false);

            if (text === "") {
              this.showPages$ = this.totalPages$;
              return filteredCallsheets;
            }

            return filteredCallsheets.pipe(
              switchMap((x) => {
                const pages = Math.ceil((x.length - this.feature) / this.itemsPerPage);
                this.showPages$ = of(pages);
                return of(x);
              })
            );
          })
        )
      )
    );

    this.callsheets$ = combined$;
  }


  handleActions(event: { action: string, callsheetId: string }) {
    switch (event.action) {
      case "delete":
        this.callsheetsService.delete({ _id: event.callsheetId } as CallSheet)
        break;
      case "preview":
        this.router.navigate(['/callsheet/view/', event.callsheetId])
        break;
      case "clone":
        this.router.navigate(['/callsheet/clone/', event.callsheetId])
        break;
      default:
        break;
    }
  }

  onPageChange(page: number) {
    this.page$.next(page)
    this.selectedPage = page
  }

  search(event: { originEvent: any, queryStr: string }) {
    this.searchText$.next(event.queryStr)
  }
}
