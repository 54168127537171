import { Component } from '@angular/core';

@Component({
  selector: 'app-clone-import',
  templateUrl: './clone-import.component.html',
  styleUrls: ['./clone-import.component.scss']
})
export class CloneImportComponent {

}
