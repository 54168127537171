import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LandingLayoutComponent } from './layout/landing-layout/landing-layout.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HeaderModule } from '@appkit4/angular-components/header';
import { AvatarModule } from '@appkit4/angular-components/avatar';
import { FooterModule } from '@appkit4/angular-components/footer';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { SharedModule } from './shared/shared.module';
import { LoginComponent } from './layout/login/login.component';
import { CoreModule } from './core/core.module';
import { AuthInterceptor } from './core/oauth/oauth.interceptor';
import { NotificationModule } from '@appkit4/angular-components/notification';
import { ConfirmComponent } from './layout/confirm/confirm.component';
import { PublicPreviewComponent } from './layout/public-preview/public-preview.component';
import { CallsheetsModule } from './main/callsheets/callsheets.module';
import { TableModule } from '@appkit4/angular-components/table';


@NgModule({
  declarations: [
    AppComponent,
    LandingLayoutComponent,
    LoginComponent,
    ConfirmComponent,
    PublicPreviewComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    RouterModule,
    AppRoutingModule,
    HeaderModule,
    AvatarModule,
    FooterModule,
    HttpClientModule,
    SharedModule,
    CoreModule,
    NotificationModule,
    CallsheetsModule,
    TableModule
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
