import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { BehaviorSubject, Observable, Subject, catchError, map, of, scan, shareReplay, startWith, switchMap, tap } from "rxjs";
import { environment } from "src/environments/env";
import { CallSheet, Schedule } from "src/app/shared/interfaces";
import { CustomNotificationService } from "src/app/shared/services/notification.service";
import { UpdateCrewDto } from "./callsheets.dto";
import { HelperService } from "src/app/shared/services/helper.service";

interface CallsheetStateAction {
    action: CallsheetStateActionType
    payload: CallSheet
}

interface CallsheetPaginateResult {
    data: CallSheet[]
    count: number
}

export enum CallsheetStateActionType {
    Init = "init",
    Load = "load",
    Add = "add",
    Remove = "remove",
    Update = "update",
    Crew = "crew",

    LoadArchive = "load-archive",
}

@Injectable({
    providedIn: 'root'
})
export class CallsheetsService {

    private callsheets: CallSheet[] = []
    private currentPage: number = 0
    public totalActive$ = new BehaviorSubject<number>(-1)

    private archived: CallSheet[] = []
    private currentArchivePage: number = 0
    public totalArchived$ = new BehaviorSubject<number>(-1)

    private action$ = new Subject<CallsheetStateAction>()

    callSheets$ = this.action$.pipe(
        startWith({ action: CallsheetStateActionType.Init, payload: {} as CallSheet } as CallsheetStateAction),
        scan((state: { data: CallSheet[], action: string }, action: CallsheetStateAction) => {
            switch (action.action) {
                case CallsheetStateActionType.Init:
                    return { data: [], action: CallsheetStateActionType.Init }
                case CallsheetStateActionType.Load:
                    return { data: [...this.callsheets], action: state.action }
                case CallsheetStateActionType.Add:
                    if (state.data.find((callsheet: CallSheet) => callsheet._id === action.payload._id)) {
                        return state
                    }
                    return { data: [...state.data, this.mapper(action.payload)], action: CallsheetStateActionType.Add }
                case CallsheetStateActionType.Remove:
                    this.totalActive$.next(this.totalActive$.value - 1)
                    return { data: state.data.filter((callsheet: CallSheet) => callsheet._id !== action.payload._id), action: '' }
                case CallsheetStateActionType.Update:
                    const index = state.data.findIndex((callsheet: CallSheet) => callsheet._id === action.payload._id)
                    if (index !== -1) {
                        const newState = [...state.data]
                        newState[index] = this.mapper(action.payload)
                        return { data: newState, action: CallsheetStateActionType.Update }
                    }
                    return { ...state, action: CallsheetStateActionType.Update }
                case CallsheetStateActionType.Crew:
                    const updatedIndex = state.data.findIndex((callsheet: CallSheet) => callsheet._id === action.payload._id)
                    if (updatedIndex !== -1) {
                        const newState = [...state.data]
                        newState[updatedIndex].crewList = action.payload.crewList
                        return { data: newState, action: CallsheetStateActionType.Crew }
                    }
                    return state;
                default:
                    return state
            }
        }, {} as { data: CallSheet[], action: string }),
        shareReplay(1)
    )

    archive$ = this.action$.pipe(
        startWith({ action: CallsheetStateActionType.Init, payload: {} as CallSheet } as CallsheetStateAction),
        scan((state: { data: CallSheet[], action: string }, action: CallsheetStateAction) => {
            switch (action.action) {
                case CallsheetStateActionType.Init:
                    return { data: [], action: CallsheetStateActionType.Init }
                case CallsheetStateActionType.LoadArchive:
                    return { data: [...this.archived], action: CallsheetStateActionType.LoadArchive }
                default:
                    return state
            }
        }, {} as { data: CallSheet[], action: string }),
        shareReplay(1)
    )

    constructor(private httpService: HttpClient, private readonly notificationService: CustomNotificationService, private readonly helperService: HelperService) { }

    getById(id: string): Observable<CallSheet> {
        return this.callSheets$.pipe(
            switchMap((state: { data: CallSheet[], action: string }) => {
                const callsheet = state.data.find(x => x._id === id)
                if (callsheet) {
                    return of(callsheet)
                } else {
                    return this.httpService.get<CallSheet>(`${environment.apiUrl}/callsheets/${id}`).pipe(
                        map(c => this.mapper(c))
                    )
                }
            })
        )
    }

    create(callsheet: CallSheet) {
        const action: CallsheetStateAction = {
            action: CallsheetStateActionType.Add,
            payload: callsheet
        }
        return this.httpService.post<CallSheet>(`${environment.apiUrl}/callsheets`, action.payload)
            .pipe(
                tap(x => this.notificationService.success('Callsheet created sucessfully.')),
                map(x => {
                    this.action$.next({ ...action, payload: x })
                    return x
                }),
                catchError(x => {
                    this.notificationService.error('Callsheet was not created.')
                    throw new Error("");
                })
            )
    }

    update(callsheet: CallSheet) {
        const action: CallsheetStateAction = {
            action: CallsheetStateActionType.Update,
            payload: callsheet
        }

        return this.httpService.put<CallSheet>(`${environment.apiUrl}/callsheets/${action.payload._id}`, action.payload)
            .pipe(
                tap(x => {
                    // if (callsheet.talents.filter(x => !x._id).length || callsheet.clients.filter(x => !x._id).length) {
                    //     this.usersService.get()
                    // }
                    this.notificationService.success('Callsheet updated sucessfully.')
                }),
                map(x => { this.action$.next({ ...action, payload: x }) }),
                catchError(x => {
                    this.notificationService.error('Callsheet was not updated.')
                    return of('Error')
                })
            )
    }

    updateCrew(payload: UpdateCrewDto) {
        const action: CallsheetStateAction = {
            action: CallsheetStateActionType.Crew,
            payload: payload as CallSheet
        }
        this.httpService.put<CallSheet>(`${environment.apiUrl}/callsheets/crew`, payload)
            .pipe(
                tap(x => this.notificationService.success('Callsheet updated sucessfully.')),
                map(x => this.action$.next(action)),
                catchError(x => {
                    this.notificationService.error('Callsheet was not updated.')
                    return of('Error')
                })
            ).subscribe()
    }

    delete(callsheet: CallSheet) {
        const action: CallsheetStateAction = {
            action: CallsheetStateActionType.Remove,
            payload: callsheet
        }
        this.httpService.delete<CallSheet>(`${environment.apiUrl}/callsheets/${action.payload._id}`)
            .pipe(
                map(x => this.action$.next(action)),
                catchError(x => of('Error'))
            ).subscribe()
    }

    search(params: Record<string, any>, useCache = true) {
        let { limit, page, archive, skip = 0, text } = params
        if (!archive) {
            return this.callSheets$.pipe(
                switchMap((state: { data: CallSheet[], action: string }) => {
                    if (useCache && (state.data.length >= (limit * (page + 1)) + skip || state.data.length == this.totalActive$.value)) {
                        return of(state.data.slice((limit * page) + skip, (limit * (page + 1)) + skip))
                    } else {
                        return this.httpService.get<CallsheetPaginateResult>(`${environment.apiUrl}/callsheets`, { params }).pipe(
                            map(x => {
                                if (this.totalActive$.value == -1) {
                                    this.totalActive$.next(x.count)
                                }

                                const action: CallsheetStateAction = {
                                    action: CallsheetStateActionType.Load,
                                    payload: {} as CallSheet
                                }

                                if (this.currentPage == page) {
                                    this.callsheets = [...this.callsheets, ...x.data.map(x => this.mapper(x))]
                                    this.currentPage++
                                    this.action$.next(action)
                                }

                                return [...x.data.map(x => this.mapper(x))]
                            }),
                            catchError(x => {
                                return of()
                            })
                        )
                    }
                })
            )
        } else {
            return this.archive$.pipe(
                switchMap((state: { data: CallSheet[], action: string }) => {
                    if (useCache && (state.data.length >= (limit * (page + 1)) + skip || state.data.length == this.totalArchived$.value)) {
                        return of(state.data.slice((limit * page) + skip, (limit * (page + 1)) + skip))
                    }
                    else {
                        return this.httpService.get<CallsheetPaginateResult>(`${environment.apiUrl}/callsheets`, { params }).pipe(
                            map(x => {
                                if (this.totalArchived$.value == -1) {
                                    this.totalArchived$.next(x.count)
                                }

                                const action: CallsheetStateAction = {
                                    action: CallsheetStateActionType.LoadArchive,
                                    payload: {} as CallSheet
                                }

                                if (this.currentArchivePage == page) {
                                    this.archived = [...this.archived, ...x.data]
                                    this.currentArchivePage++
                                    this.action$.next(action)
                                }

                                return x.data
                            }),
                            catchError(x => {
                                return of()
                            })
                        )
                    }
                })
            );
        }
    }

    sendEnvoyInvites(callsheetId: string, crewList: string[]) {
        const action: CallsheetStateAction = {
            action: CallsheetStateActionType.Update,
            payload: {} as CallSheet
        }
        this.httpService.post<CallSheet>(`${environment.apiUrl}/callsheets/${callsheetId}/envoy`, crewList)
            .pipe(
                map(x => {
                    this.notificationService.success('Envoy invites sent.')
                    this.action$.next({ ...action, payload: x })
                }),
                catchError(x => {
                    this.notificationService.error(x?.error?.message || '')
                    return of(x.message)
                })
            ).subscribe()
    }

    sendEmailNotifications(callsheetId: string, notifications: string[]) {
        const action: CallsheetStateAction = {
            action: CallsheetStateActionType.Crew,
            payload: {} as CallSheet
        }
        this.httpService.post<CallSheet>(`${environment.apiUrl}/callsheets/${callsheetId}/notify`, notifications)
            .pipe(
                map(x => {
                    this.notificationService.success('Emails sent.')
                    this.action$.next({ ...action, payload: x })
                }),
                catchError(x => {
                    this.notificationService.error(x?.error?.message || '')
                    return of(x.message)
                })
            ).subscribe()
    }

    generatePDF(callsheetId: string) {
        return this.httpService.get(`${environment.apiUrl}/callsheets/${callsheetId}/pdf`, { responseType: 'arraybuffer' })
    }


    private mapper(c: CallSheet) {
        const timezoneOffset = c.location.timezone
        return {
            ...c,
            shootDate: this.helperService.getLocationTimezoneDate(c.shootDate, timezoneOffset)!,
            filmingStart: this.helperService.getLocationTimezoneDate(c.filmingStart!, timezoneOffset),
            talentCall: this.helperService.getLocationTimezoneDate(c.talentCall, timezoneOffset),
            ...(c.schedule && {
                schedule: c.schedule.map(s => {
                    return {
                        ...s,
                        time: this.helperService.getLocationTimezoneDate(s.time, timezoneOffset)
                    }
                }).sort((a: Schedule, b: Schedule) => {
                    if (!a.time || !b.time) return 0
                    return a.time.getTime() - b.time.getTime()
                })
            }),
            ...(c.talents && {
                talents: c.talents.map(s => {
                    return {
                        ...s,
                        callTime: this.helperService.getLocationTimezoneDate(s.callTime, timezoneOffset),
                        makeupTime: this.helperService.getLocationTimezoneDate(s.makeupTime, timezoneOffset),
                        shootingTime: this.helperService.getLocationTimezoneDate(s.shootingTime, timezoneOffset),
                        timeout: this.helperService.getLocationTimezoneDate(s.timeout, timezoneOffset),
                    }
                })
            }),
            crewList: c.crewList.map(x => {
                return {
                    ...x,
                    callTime: this.helperService.getLocationTimezoneDate(x.callTime, timezoneOffset)
                }
            }),
            ...(c.clients && {
                clients: c.clients.map(x => {
                    return {
                        ...x,
                        arrivalTime: this.helperService.getLocationTimezoneDate(x.arrivalTime, timezoneOffset)
                    }
                })
            })
        }
    }

    getPublicView(callsheetId: string): Observable<{ isArchive: boolean, callsheet: CallSheet }> {
        return this.httpService.get<{ isArchive: boolean, callsheet: CallSheet }>(`${environment.apiUrl}/callsheets/view/public/${callsheetId}`)
    }

}