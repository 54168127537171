<div *ngIf="callsheet$ | async as callsheet">
    <div class="row ap-my-spacing-7">
        <div class="col-12 ap-pb-spacing-8">
            <h2>Select the call sheet sections you wish to clone</h2>
        </div>
        <hr class="hr">

        <div class="row">
            <div class="d-flex align-items-center justify-content-between">
                <h3><strong>Details</strong></h3>
                <ap-toggle [(ngModel)]="detailsSection" >
                    <span>Clone</span>
                </ap-toggle>
            </div>

            <div class="col-12 col-sm-6 col-md-3">
                <app-time-panel [callSheet]="callsheet"></app-time-panel>
            </div>

            <div class="col-6 col-sm-6 col-md-3">
                <p>PRODUCERS</p>
                <div class="d-flex flex-column ap-my-spacing-4">
                    <strong>Lead Producer</strong>
                    <div *ngFor="let producer of callsheet.producers">
                        <ng-container *ngIf="producer.lead">
                            <p class="ap-my-spacing-1">{{producer.firstName}} {{producer.lastName}} <br>
                                {{producer.email}}<br>
                                {{producer.phone}}
                            </p>
                        </ng-container>
                    </div>
                    <strong class="ap-mt-spacing-5">Additional Producers</strong>
                    <div *ngFor="let producer of callsheet.producers">
                        <ng-container *ngIf="!producer.lead">
                            <p class="ap-my-spacing-1">{{producer.firstName}} {{producer.lastName}}</p>
                        </ng-container>
                    </div>
                </div>

            </div>

            <div class="col-6 col-md-3">
                <p class="ap-mb-spacing-4">VENUE CONTACT</p>
                <p class="ap-my-spacing-1"><strong>{{callsheet.location.contact.firstName}}
                        {{callsheet.location.contact.lastName}} </strong><br>
                    {{callsheet.location.contact.phone}} <br>
                    {{callsheet.location.contact.email}}
                </p>
            </div>

            <div class="col-6 col-md-3">
                <p class="ap-mb-spacing-4">LOCATION</p>
                <strong>{{callsheet.location.name}}</strong>
                <p class="ap-my-spacing-1">
                    {{callsheet.location.firstStreet}}, {{callsheet.location.city}} <br>
                    {{callsheet.location.state}}, {{callsheet.location.zipCode}}
                </p>
                <strong>Location Security Office Responsible</strong>
                <p class="ap-my-spacing-1">{{callsheet.location.securityContact}}</p>
                <div *ngIf="callsheet.location.notes">
                    <strong>Notes</strong>
                    <p class="ap-my-spacing-1">{{callsheet.location.notes}}</p>
                </div>
            </div>

            <div *ngIf="callsheet.notes" class="row ap-mt-spacing-4">
                <div class="col-12">
                    <h3>Notes</h3>
                    <p>
                        {{callsheet.notes}}
                    </p>
                </div>
            </div>


        </div>

        <!-- TALENT SECTION -->
        <div *ngIf="callsheet.talents.length" class="row ap-my-spacing-4">
            <hr class="hr">
            <div class="d-flex align-items-center justify-content-between">
                <h3><strong>Talents</strong></h3>
                <ap-toggle [(ngModel)]="talentsSection" >
                    <span>Clone</span>
                </ap-toggle>
            </div>
            <app-panel [actions]="[]" class="col-md-4 col-sm-6" *ngFor="let talent of callsheet.talents">
                <app-business-card class="talentCard" [title]="talent.firstName + ' ' + talent.lastName"
                    [items]="getItems(talent)" [notes]="talent.talentNotes"
                    [image]="talent.headshot ? blobUrl + talent.headshot : '/assets/default-avatar.png'"></app-business-card>
            </app-panel>
        </div>

        <!-- CLIENT SECTION -->
        <div *ngIf="callsheet.clients.length" class="row ap-my-spacing-4">
            <hr class="hr">
            <div class="d-flex align-items-center justify-content-between">
                <h3><strong>Clients</strong></h3>
                <ap-toggle [(ngModel)]="clientsSection" >
                    <span>Clone</span>
                </ap-toggle>
            </div>

            <div class="col-12">
                <ap-table #table [(originalData)]="callsheet.clients" [condensed]="true" [striped]="true">
                    <table>
                        <thead>
                            <tr>
                                <th>
                                    <span>Name</span>
                                </th>
                                <th>
                                    <span>Email</span>
                                </th>
                                <th>
                                    <span>Phone</span>
                                </th>
                                <th>
                                    <span>Arrival Time</span>
                                </th>
                                <th>
                                    <span>Notes</span>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let client of callsheet.clients;">
                                <td>
                                    {{client.firstName}} {{client.lastName}}
                                </td>
                                <td>
                                    {{client.email}}
                                </td>
                                <td>
                                    {{client.phone}}
                                </td>
                                <td>
                                    {{client.arrivalTime | date: "hh:mm a" || 'TBA'}}
                                </td>
                                <td style="max-width: 20rem;">
                                    {{client.notes}}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </ap-table>
            </div>

        </div>

        <!-- VENDOR SECTION -->
        <div *ngIf="callsheet.vendors.length" class="row ap-my-spacing-4">
            <hr class="hr">
            <div class="d-flex align-items-center justify-content-between">
                <h3><strong>Vendors</strong></h3>
                <ap-toggle [(ngModel)]="vendorsSection" (onChange)="selectVendorsSection($event)">
                    <span>Clone</span>
                </ap-toggle>
            </div>

            <div class="col-12">
                <ap-table #table [(originalData)]="callsheet.vendors" [condensed]="true" [striped]="true">
                    <table>
                        <thead>
                            <tr>
                                <th>
                                    <span>Company Name</span>
                                </th>
                                <th>
                                    <span>Owner</span>
                                </th>
                                <th>
                                    <span>Email</span>
                                </th>
                                <th>
                                    <span>Role</span>
                                </th>
                                <th>
                                    <span>Requirements</span>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let vendor of callsheet.vendors;">
                                <td>
                                    {{vendor.companyName}}
                                </td>
                                <td>
                                    {{vendor.firstName}} {{vendor.lastName}}
                                </td>
                                <td>
                                    {{vendor.email}}
                                </td>
                                <td>
                                    {{vendor.role.join(',')}}
                                </td>
                                <td>
                                    {{vendor.requirements}}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </ap-table>
            </div>

        </div>

        <!-- CREW SECTION -->
        <div *ngIf="callsheet.crewList.length" class="row ap-my-spacing-4">
            <hr class="hr">
            <div class="d-flex align-items-center justify-content-between">
                <h3><strong>Crew</strong></h3>
                <ap-toggle [(ngModel)]="crewSection"  [disabled]="!vendorsSection">
                    <span>Clone</span>
                </ap-toggle>
            </div>
            <div class="col-12">
                <ap-table #table [(originalData)]="callsheet.crewList" [condensed]="true" [striped]="true">
                    <table>
                        <thead>
                            <tr>
                                <th>
                                    <span>Name</span>
                                </th>
                                <th>
                                    <span>Email</span>
                                </th>
                                <th>
                                    <span>Phone</span>
                                </th>
                                <th>
                                    <span>Arrival Time</span>
                                </th>
                                <th>
                                    <span>Notes</span>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let crew of callsheet.crewList;">
                                <td>
                                    {{crew.firstName}} {{crew.lastName}}
                                </td>
                                <td>
                                    {{crew.email}}
                                </td>
                                <td>
                                    {{crew.phone}}
                                </td>
                                <td>
                                    {{crew.callTime | date: "hh:mm a" || 'TBA'}}
                                </td>
                                <td style="max-width: 20rem;">
                                    {{crew.crewNotes}}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </ap-table>
            </div>
        </div>

        <div *ngIf="callsheet.schedule.length" class="row ap-my-spacing-4">
            <hr class="hr">
            <div class="d-flex align-items-center justify-content-between">
                <h3><strong>Schedule</strong></h3>
                <ap-toggle [(ngModel)]="scheduleSection" >
                    <span>Clone</span>
                </ap-toggle>
            </div>
            <div class="col-12">
                <ap-table #schedule [(originalData)]="callsheet.schedule" [condensed]="true" [striped]="true">
                    <table>
                        <thead>
                            <tr>
                                <th>
                                    <span>Time</span>
                                </th>
                                <th>
                                    <span>Activity</span>
                                </th>
                                <th>
                                    <span>Notes</span>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of callsheet.schedule">
                                <td>
                                    {{item.time | date: "hh:mm a" || 'TBA'}}
                                </td>
                                <td>
                                    {{item.activity}}
                                </td>
                                <td style="max-width: 20rem;">
                                    {{item.notes}}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </ap-table>
            </div>
        </div>

    </div>
    <hr class="hr">

    <div class="ap-py-spacing-4  d-flex justify-content-end gap-2 sticky">
        <ap-button type="submit" [btnType]="'primary'" [label]="'Clone selected sections'"
            [icon]="'duplicate-outline'" (onClick)="cloneSelected()"></ap-button>
    </div>
</div>