import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Observable, of, startWith, switchMap } from 'rxjs';

export interface RoleListFieldItem {
  value: string,
  checked?: boolean
}

@Component({
  selector: 'app-role-list',
  templateUrl: './role-list.component.html',
  styleUrls: ['./role-list.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: RoleListComponent,
      // useExisting: forwardRef(() => ListFieldComponent),
      multi: true
    }
  ]
})
export class RoleListComponent implements ControlValueAccessor {
  selectedRoles: string[] = []
  inputStr: string = ''
  expand: boolean = false
  noFiltersFound: boolean = false;

  roles: RoleListFieldItem[] = [
    { value: "Cam Op - 1" },
    { value: "Cam Op - 2" },
    { value: "Cam Op - 3" },
    { value: "Cam Op - Jib" },
    { value: "Cam Op - Robo" },
    { value: "Cam Assist - 1st" },
    { value: "Cam Assist - 2nd" },
    { value: "Director of Photography" },
    { value: "Technical Director" },
    { value: "Teleprompter Op" },
    { value: "TeleprompterOp - wGear" },
    { value: "Video Playback" },
    { value: "Video Utility" },
    { value: "Audio - Engineer" },
    { value: "Audio - Tech" },
    { value: "Audio - Field Mixer wGear" },
    { value: "Audio - Boom Op" },
    { value: "PA -1" },
    { value: "PA -2" },
    { value: "PA -3" },
    { value: "Hair & Makeup - 1" },
    { value: "Hair & Makeup - 2" },
    { value: "Hair & Makeup -3" },
    { value: "Prop - Assistant" },
    { value: "Prop - Master" },
    { value: "Scenic Designer" },
    { value: "Stage Manager" },
    { value: "Gaffer" },
    { value: "Grip" },
    { value: "Key Grip" },
    { value: "Carpenter" },
    { value: "Electrician" }
  ];
  

  private _placeholder!: string;
  @Input()
  set placeholder(str: string) {
    this._placeholder = str || 'Select an option';
  }

  @Input()
  showBorder: boolean = false

  @Input()
  itemsToShow: number = 4

  get placeholder() {
    return this._placeholder;
  }

  @Output()
  onChangeEvent = new EventEmitter<string[]>()

  searchInput: FormControl = new FormControl('')
  filterRoles$: Observable<RoleListFieldItem[]>

  writeValue(str: string[]): void {
    this.selectedRoles = str || []
    this.roles = this.roles.map(x => {
      return {
        ...x,
        checked: !!~this.selectedRoles.findIndex(s => s == x.value)
      }
    })
    this.inputStr = this.selectedRoles.slice(0, this.itemsToShow).join(', ')
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void { }

  onChange = (value: any) => { };

  onListChange() {
    this.selectedRoles = [...this.roles.filter(x => x.checked).map(x => x.value)]
    this.inputStr = this.selectedRoles.slice(0, this.itemsToShow).join(', ')
    this.onChange(this.selectedRoles);
    this.onChangeEvent.emit(this.selectedRoles)
  }

  constructor() {
    this.filterRoles$ = this.searchInput.valueChanges.pipe(
      startWith(''),
      switchMap((x: string) => {
        const filteredRoles = this.roles.filter(item => item.value.toLowerCase().includes(x.toLowerCase()));
        this.noFiltersFound = filteredRoles.length === 0;         
        return of(filteredRoles);
      })
    );
    
  }
}
