import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/env";
import { of } from "rxjs";

@Injectable({
    providedIn: 'root'
})
export class WorkfrontService {
    constructor(private httpService: HttpClient) { }

    getLogguedUser(userId: string, sessionId: string) {
        return this.httpService.get(`${environment.apiUrl}/workfront/auth?sessionId=${sessionId}&userId=${userId}`)
    }

    getById(id: string) {
        return this.httpService.get(`${environment.apiUrl}/workfront/project?id=${id}`)
    }

    getProjectOwner(id: string) {
        return this.httpService.get(`${environment.apiUrl}/workfront/project/owner?id=${id}`)
    }
}