import { Component, Input, OnInit } from '@angular/core';
import { CallSheet, LocationContact, Producer, User } from '../../../../../shared/interfaces/callsheet.interface';
import { Router } from '@angular/router';
import { environment } from 'src/environments/env';

@Component({
  selector: 'app-upcoming-callsheet',
  templateUrl: './upcoming-callsheet.component.html',
  styleUrls: ['./upcoming-callsheet.component.scss']
})
export class UpcomingCallsheetComponent implements OnInit {
  @Input() callsheet!: CallSheet
  @Input() showProjectUrl: boolean = false

  venueContact?: User
  times: {label: string, value?: Date}[] = []
  projectUrl: string = `${environment.workfrontUrl}/project/`

  constructor(private router: Router) { }

  ngOnInit(): void {
    this.projectUrl = `${this.projectUrl}${this.callsheet.projID}`
    this.venueContact = this.callsheet.location?.contact
    this.times = [
      {label: 'CREW CALL', value: this.callsheet.shootDate},
      {label: 'TALENT CALL', value: this.callsheet.talentCall},
      {label: 'FIMING STARTS', value: this.callsheet.filmingStart},
    ]
  }

  preview() {
    this.router.navigate(['/callsheet/view/', this.callsheet._id])
  }
}
