import { Component, EventEmitter, Input, Output } from '@angular/core';

export interface BusinessCardItem {
  icon: string;
  content: string;
  link: boolean
}

@Component({
  selector: 'app-business-card',
  templateUrl: './business-card.component.html',
  styleUrls: ['./business-card.component.scss']
})

export class BusinessCard {

  @Output() cardBtnClickEvent = new EventEmitter<any>();

  @Input() title: string = ''
  @Input() description: string = ''
  @Input() notes: string = ''
  @Input() backgroundColor: string = ''
  @Input() image: string = ''
  @Input() items: BusinessCardItem[] = []

  trackId(index: number, item: BusinessCardItem) {
    return item.content;
  }
}
