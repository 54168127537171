import { Directive, ElementRef, AfterViewInit } from '@angular/core';

@Directive({
    selector: '[appWrapWithUnderscore]'
})
export class WrapWithUnderscoreDirective implements AfterViewInit {
    constructor(private el: ElementRef) { }

    ngAfterViewInit(): void {       
        let text = this.el.nativeElement.innerHTML;
        text = text.replace(/_/g, '\u200B_');
        this.el.nativeElement.innerHTML = text; 
    }
}
