<div class="row">
    <div class="col-12 col-sm-6 col-md-6 ap-mb-spacing-7">
        <ap-field [title]="'Project Name'">
            <input appkit-field [(ngModel)]="details.projectName"
                (ngModelChange)="inputTextChange.next({text: $event, prop: 'projectName'})" />
        </ap-field>
    </div>

    <ap-field [title]="'Shoot date'" [datepicker]="true" [dpType]="'double'" [dpRange]="false"
        class="col-xs-12 col-sm-3 ap-mb-spacing-7" [dpMinDate]="minDate" [(dpSelectedDates)]="details.shootDate"
        (dpSelectedDatesChange)="onShootDateChange($event)">
        <input appkit-field />
    </ap-field>

    <div class="col-12 col-sm-3 col-md-3 ap-mb-spacing-7">
        <ap-field [title]="'Reference ID'" [readonly]="true" [type]="'appkit-number'">
            <input appkit-field [(ngModel)]="details.referenceID" />
        </ap-field>
    </div>

    <div class="col-sm-3 ap-mb-spacing-7" *ngIf="(locations$ | async) as locations">
        <ap-dropdown [list]="locations" [selectType]="'single'" [placeholder]="'Shoot location'"
            (onSelect)="onLocationChange($event.selected.ref)" [title]="'Shoot location'"
            [(ngModel)]="details.location">
            <ng-container *ngFor="let item of locations; let i = index;">
                <ap-dropdown-list-item #dropdownListItem [item]="item">
                </ap-dropdown-list-item>
            </ng-container>
        </ap-dropdown>
    </div>

    <div class="col-sm-3 ap-mb-spacing-7" *ngIf="(locations$ | async) as locations">
        <ap-field [title]="'Location contact'" [readonly]="true">
            <input appkit-field [value]="details.location?.ref?.securityContact" />
        </ap-field>
    </div>

    <div class="col-sm-6 ap-mb-spacing-7" *ngIf="(producers$ | async) as producers">
        <ap-dropdown [list]='producers' [selectType]="'multiple'" [title]="'Producers'" [(ngModel)]="details.producers"
            (onSelect)="onProducersChange($event.selected)">
            <ng-container *ngFor="let item of producers; let i = index;">
                <ap-dropdown-list-item #dropdownListItem [item]="item" [role]="'checkbox'" [showCheckbox]="true">
                    <ap-button *ngIf="item.value != producerLeadId && item.checked" [btnType]="'text'" [type]="'button'"
                        [compact]="true" (onClick)="setProducerLead($event,producers,item.value)"
                        [styleClass]="'lead-btn'">Mark
                        as lead</ap-button>
                    <ap-badge *ngIf="item.value == producerLeadId && item.checked" value="Lead" [type]="'primary'"
                        size="small"></ap-badge>
                </ap-dropdown-list-item>
            </ng-container>
        </ap-dropdown>
    </div>

    <ap-timepicker [inline]="false" [title]="'Crew call'" [hourTime]="12" [(ngModel)]="details.crewCall"
        [ngModelOptions]="{standalone: true}" (onValueChange)="onTimePickerChange($event, 'shootDate')"
        class="col-12 col-sm-4 col-md-4 ap-mb-spacing-7">
    </ap-timepicker>

    <ap-timepicker [inline]="false" [hourTime]="12" [title]="'Talent call'" [(ngModel)]="details.talentCall"
        (onValueChange)="onTimePickerChange($event, 'talentCall')" [ngModelOptions]="{standalone: true}"
        class="col-12 col-sm-4 col-md-4 ap-mb-spacing-7">
    </ap-timepicker>

    <ap-timepicker [inline]="false" [hourTime]="12" [title]="'Filming starts'" [(ngModel)]="details.filmingStart"
        [ngModelOptions]="{standalone: true}" (onValueChange)="onTimePickerChange($event, 'filmingStart')"
        class="col-12 col-sm-4 col-md-4 ap-mb-spacing-7"></ap-timepicker>
</div>
<div class="row">
    <ap-field [title]="'Notes'">
        <textarea appkit-field [(ngModel)]="details.notes"
            (ngModelChange)="inputTextChange.next({text: $event, prop: 'notes'})"></textarea>
    </ap-field>
</div>