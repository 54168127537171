<ap-modal #modal appModalId="user-form-modal-{{modalId}}" [title]="(isNew ? 'Create ' : 'Update ') + objectType" [style]="style"
    [contentStyle]="contentStyle" class="modal" (onClose)="onClose()">
    <form *ngIf="modal" [formGroup]="userForm" (ngSubmit)="submitForm()">
        <div class="ap-container ap-px-spacing-1">
            <div class="row ap-mb-spacing-6 ap-mt-spacing-4">
                <div class="col-12 col-sm-6 col-md-3 d-flex flex-column avatar">
                    <div class="avatar">
                        <label for="file-input">
                            <img [src]="userForm.value.headshot ? assetsUrl + userForm.value.headshot : defaultAvatar"
                                alt="avatar">
                            <div class="avatar-backdrop">
                                <i class="Appkit4-icon icon-camera-fill"></i>
                                <span>Edit</span>
                            </div>
                        </label>
                        <input type="file" id="file-input" name="" accept="image/png, image/jpeg"
                            (change)="onFileChange($event)">
                    </div>
                    <span *ngIf="userForm.value.headshot" class="remove-btn" (click)="removeAvatar()"><i
                            class="Appkit4-icon icon-camera-fill"></i>Remove</span>
                </div>
                <div class="col-12 col-sm-6 col-md-9 d-flex flex-column">
                    <ap-field [title]="'First name'" [required]="true">
                        <input appkit-field formControlName="firstName" />
                        <div *ngIf="!userForm.controls['firstName'].valid && userForm.controls['firstName'].touched"
                            class="validation-error">First name is required</div>
                    </ap-field>

                    <ap-field [title]="'Last name'" class="ap-mt-spacing-6" [required]="true">
                        <input appkit-field formControlName="lastName" />
                        <div *ngIf="!userForm.controls['lastName'].valid && userForm.controls['lastName'].touched"
                            class="validation-error">Last name is required</div>
                    </ap-field>
                </div>
            </div>
            <div class="row">
                <div class="col-12 col-sm-6 col-md-6 ap-mb-spacing-6">
                    <ap-field [title]="'Email'" [disabled]="!isNew && objectType.toLowerCase() === 'user'" [required]="true">
                        <input type="email" appkit-field formControlName="email" />
                        <div *ngIf="!userForm.controls['email'].valid && userForm.controls['email'].touched"
                            class="validation-error">Please enter a valid email address</div>
                    </ap-field>
                </div>

                <div class="col-12 col-sm-6 col-md-6 ap-mb-spacing-6">
                    <ap-field [title]="'Phone'">
                        <input appkit-field formControlName="phone" />
                        <div *ngIf="!userForm.controls['phone'].valid && userForm.controls['phone'].touched"
                            class="validation-error">Please enter a valid phone number</div>
                    </ap-field>
                </div>

                <ng-container *ngIf="objectType == 'crew'">
                    <div class="col-12 ap-mb-spacing-6">
                        <app-role-list [placeholder]="'Roles'" [formControlName]="'roles'" (onChangeEvent)="onRowChange($event)" [showBorder]="true"></app-role-list>
                    </div>
                </ng-container>

                <div class="col-12 ap-mb-spacing-6">
                    <ap-field [title]="'Notes'">
                        <textarea appkit-field formControlName="notes" maxlength="420"></textarea>
                    </ap-field>
                </div>

                <ng-container *ngIf="objectType == 'user'">
                    <div class="col-12 ap-mb-spacing-6">
                        <ap-checkbox formControlName="isProducer">
                            <span>Producer</span>
                        </ap-checkbox>
                    </div>
                </ng-container>
            </div>
            <div class="d-flex justify-content-end">
                <ap-button type="button" class="ap-mr-spacing-4" [btnType]="'secondary'" (click)="onClose()"
                    [label]="'Cancel'"></ap-button>
                <ap-button type="submit" [btnType]="'primary'" [label]="isNew ? 'Create' : 'Update'"
                    [disabled]="!userForm.valid || (!headshot.size && (updatedUser | json) == '{}')"></ap-button>
            </div>
        </div>
    </form>
</ap-modal>