import { Component, ViewChild } from '@angular/core';
import { Observable, catchError, delay, map, shareReplay, tap } from 'rxjs';
import { CallSheet } from 'src/app/shared/interfaces';
import { GenericFormTableColumn } from 'src/app/main/callsheets/shared/components/generic-form-table/generic-form-table.component';
import { CallsheetsService } from 'src/app/main/callsheets/callsheets.service';
import { ActivatedRoute, Router } from '@angular/router';
import { HelperService } from 'src/app/shared/services/helper.service';
import { AbstractControl, ValidationErrors } from '@angular/forms';

@Component({
  selector: 'app-video-team-form',
  templateUrl: './video-team-form.component.html',
  styleUrls: ['./video-team-form.component.scss']
})
export class VideoTeamFormComponent {

  constructor(
    private readonly callsheetsService: CallsheetsService,
    private readonly helperService: HelperService,
    private router: Router,
    private route: ActivatedRoute
  ) { }

  @ViewChild('modal', { static: false }) modal: any;

  loading: boolean = true
  callsheet$!: Observable<CallSheet>
  callSheetId: string = ''
  updatedCallsheet: Partial<CallSheet> = {}

  showEnvoyInviteButton: boolean = false
  enableNotification: boolean = false

  timezone: number = 0

  callsheetForm: Partial<CallSheet> = {
    vendors: [],
    clients: [],
    crewList: [],
    schedule: [],
    talents: [],
  }

  talentsTableConfig: GenericFormTableColumn[] = [
    {
      value: 'Name',
      key: 'firstName',
      type: 'avatar',
      editable: true
    },
    {
      value: 'Call Time',
      key: 'callTime',
      type: 'timepicker',
    },
    {
      value: 'Makeup Time',
      key: 'makeupTime',
      type: 'timepicker',
    },
    {
      value: 'Shooting Time',
      key: 'shootingTime',
      type: 'timepicker',
    },
    {
      value: 'Timeout',
      key: 'timeout',
      type: 'timepicker',
    },
    {
      value: 'Notes',
      key: 'talentNotes',
      type: 'text',
      placeholder: 'Notes',
      editable: true
    }
  ]

  crewTableConfig: GenericFormTableColumn[] = [
    {
      value: 'Name',
      key: 'firstName',
      type: 'avatar',
    },
    {
      value: 'Phone',
      key: 'phone',
      type: 'text'
    },
    {
      value: 'Email',
      key: 'email',
      type: 'text'
    },
    {
      value: 'Job Title',
      key: 'jobTitle',
      type: 'text',
      placeholder: 'Job Title'
    },
    {
      value: 'Call Time',
      key: 'callTime',
      type: 'timepicker',
    },
    {
      value: 'Notes',
      key: 'crewNotes',
      type: 'text',
      placeholder: 'Notes',
      editable: true
    }
  ]

  clientTableConfig: GenericFormTableColumn[] = [
    {
      value: 'Name',
      key: 'firstName',
      type: 'avatar',
      editable: true
    },
    {
      value: 'Email',
      key: 'email',
      type: 'text',
    },
    {
      value: 'Phone',
      key: 'phone',
      type: 'text'
    },
    {
      value: 'Arrival Time',
      key: 'arrivalTime',
      type: 'timepicker',
    },
  ]

  vendorsTableConfig: GenericFormTableColumn[] = [
    {
      value: 'Company',
      key: 'companyName',
      type: 'text',
    },
    {
      value: 'Email',
      key: 'email',
      type: 'text',
    },
    {
      value: 'Role',
      key: 'role',
      type: 'roleDropdown',
      placeholder: 'Choose roles...',
      editable: true
    },
    {
      value: 'Notes',
      key: 'requirements',
      type: 'text',
      placeholder: 'Notes',
      editable: true
    }
  ]

  scheduleTableConfig: GenericFormTableColumn[] = [
    {
      value: 'Time',
      key: 'time',
      type: 'timepicker',
      editable: true

    },
    {
      value: 'Activity',
      key: 'activity',
      type: 'text',
      placeholder: 'Activity',
      editable: true
    },
    {
      value: 'Notes',
      key: 'notes',
      type: 'text',
      placeholder: 'Notes',
      editable: true
    }
  ]

  requiredOneScheduleInputControl: ValidationErrors | null = () => {
    return (formGroup: AbstractControl) => {
      const time = formGroup.get('time')?.value;
      const activity = formGroup.get('activity')?.value;

      if (!time && !activity) {
        return { required: 'at least one of the items is required' };
      }
      return null;
    }
  }

  ngOnInit() {
    this.callSheetId = this.route.snapshot.params['id']
    this.callsheet$ = this.callsheetsService.getById(this.callSheetId).pipe(
      delay(0),
      map((c: CallSheet) => {
        this.callsheetForm = {
          vendors: c.vendors,
          clients: c.clients,
          crewList: c.crewList,
          schedule: c.schedule,
          talents: c.talents,
          includeTalentOnPrint: c.includeTalentOnPrint ?? true,
          includeClientOnPrint: c.includeClientOnPrint ?? true,
          includeCrewOnPrint: c.includeCrewOnPrint ?? true
        }
        this.enableNotification = !!c.shootDate && !!c.producers.length
        this.showEnvoyInviteButton = this.enableNotification && !!c.location.envoyLocationId
        this.loading = false
        this.timezone = c.location.timezone
        return c
      }),
      shareReplay(1),
      catchError(err => {
        this.router.navigate([''])
        throw err
      }))
  }

  onTableChange(value: any, prop: string) {
    this.updatedCallsheet = {
      ...this.updatedCallsheet,
      [prop]: value
    }
  }

  onTogglePrivacity(event: any, prop: string) {
    this.callsheetForm[prop] = event.switchState
    this.updatedCallsheet = {
      ...this.updatedCallsheet,
      [prop]: event.switchState
    }
  }

  onDetailsChange($event: Partial<CallSheet>) {
    this.updatedCallsheet = {
      ...this.updatedCallsheet,
      ...$event
    }
  }

  submitForm() {
    for (const key of Object.keys(this.updatedCallsheet)) {
      if (this.updatedCallsheet[key] === null || this.updatedCallsheet[key] === undefined) {
        delete this.updatedCallsheet[key];
      }
      if (this.updatedCallsheet[key] instanceof Array) {
        this.updatedCallsheet[key] = this.updatedCallsheet[key].map((x: any) => {
          for (const key of Object.keys(x)) {
            if (x[key] instanceof Date) {
              x[key] = this.helperService.parseTimezoneDate(x[key], this.timezone)
            }
          }
          return x
        })
      }
    }



    const paylaod = {
      _id: this.callSheetId,
      ...this.updatedCallsheet,
    } as CallSheet

    this.loading = true
    this.updatedCallsheet = {}
    this.callsheetsService.update(paylaod).subscribe()
  }

  sendEnvoyInvites(crewList: string[]) {
    this.callsheetsService.sendEnvoyInvites(this.callSheetId, crewList)
    this.modal.closeModal('envoy-invites-modal')
  }

  sendEmailNotifications(notifications: string[]) {
    this.loading = true
    this.callsheetsService.sendEmailNotifications(this.callSheetId, notifications)
    this.modal.closeModal('email-notification-modal')
  }
}
