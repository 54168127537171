<div class="row">
    <div class="col-8">
        <h3>Vendors, location, users and archive page</h3>
        <p>
            Access these pages from the left side menu to add a new vendor, different location or external user to the
            database (all PMDM’s users from Mexico and the US are already uploaded to the system). Use the archive page,
            which lists all past or canceled call sheets, to preview and clone them.
        </p>
        <p>
            The vendors, locations and users pages share a simple and similar structure, sorting information in
            different cards, with the option to edit and delete content, and a search bar to look for keywords.
        </p>
        <p>
            The “new” button on the top right corner opens a pop-up modal to fill out details and basic information
            needed to add the new vendor, location or user to the system. 
        </p>
        <p>
            Please watch the accompanying walkthrough video to learn more. </p>
            <div>
                <video controls class="w-100">
                    <source src="../../../assets/video-tutorials/vendors_location_users_archive.mov" type="video/mp4" />
    
                    Your browser does not support the video tag.
                </video>
            </div>

    </div>
    <hr class="ap-my-spacing-7">
</div>