import { Injectable } from '@angular/core'
import { RouterStateSnapshot } from '@angular/router'
import { tap } from 'rxjs/operators'
import { OauthAdapterService } from '../oauth'

@Injectable()
export class AuthGuard {
    constructor(private readonly authService: OauthAdapterService) { }

    canActivate(state: RouterStateSnapshot) {
        return this.isAuth(state.url);
    }

    canActivateChild(state: RouterStateSnapshot) {
        return this.isAuth(state.url)
    }

    isAuth(url: string) {
        return this.authService.checkIsAuth$.pipe(tap(isAuthenticated => isAuthenticated || this.authService.login(url).subscribe()));
    }
}