<ap-table #table [(originalData)]="data" [(data)]="tableData" class="app-table" [styleClass]="'custom-table'"
    [condensed]="false">
    <table>
        <thead>
            <tr>
                <th *ngFor="let column of columns" ap-sort [sortKey]="column.key">
                    <span>{{column.value}}</span>
                    <app-user-form-modal *ngIf="column.type == 'avatar' && column.editable && id" [objectType]="'User'" [modalId]="id" (onSubmitEvent)="onUserSubmit($event)"></app-user-form-modal>
                </th>
                <th></th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let data of tableData;let i = index; trackBy: trackId">
                <td *ngFor="let c of columns;" [ngSwitch]="c.type">
                    <div *ngSwitchCase="'avatar'" class="d-flex align-items-center">
                        <div class="avatar-wrapper">
                            <img [src]="data.headshot ? blobUrl + data.headshot : '/assets/default-avatar.png'"
                                alt="avatar" [width]="avatarDiameter" [height]="avatarDiameter">
                            <div class="avatar-backdrop" *ngIf="c.editable">
                                <i class="Appkit4-icon icon-edit-outline" (click)="showUserModal(data)"></i>
                            </div>
                        </div>
                        <span>{{data.firstName + ' ' + data.lastName}}</span>
                        <span *ngIf="data.notes" class="Appkit4-icon icon-information-outline ap-ml-spacing-2"
                            ap-tooltip [tooltipId]="'tooltipDesc'" ariaDescribedby="tooltipDesc"
                            [tooltipContent]="data.notes" [direction]="'top'"></span>
                    </div>
                    <ng-container *ngSwitchCase="'text'">
                        <input #input *ngIf="c.editable" class="custom_input" type="text" [value]="data[c.key] || ''"
                            [placeholder]="c.placeholder || ''" (focusout)="onTableChange(input.value, c.key, i)" />
                        <div *ngIf="!c.editable">{{data[c.key]}}</div>
                    </ng-container>
                    <ng-container *ngSwitchCase="'roleDropdown'">
                        <app-role-list [placeholder]="c.placeholder!" [(ngModel)]="data[c.key]" (onChangeEvent)="onTableChange($event, c.key, i)" ></app-role-list>
                    </ng-container>

                    <ap-timepicker *ngSwitchCase="'timepicker'" hourTitle="HH" minuteTitle="MM"
                        [styleClass]="'custom-timepicker'" [hideTitleOnInput]="true" [inline]="false" [hourTime]="12"
                        [(ngModel)]="data[c.key]" (onValueChange)="onTableChange($event, c.key, i)">
                    </ap-timepicker>
                </td>
                <td>
                    <ap-button [type]="'button'" [btnType]="'text'" [icon]="'delete-outline'"
                        (onClick)="delete(i)"></ap-button>
                </td>
            </tr>
        </tbody>
        <tfoot *ngIf="type == 'inline'" [formGroup]="inlineForm">
            <tr>
                <td *ngFor="let c of columns;let i = index" [ngSwitch]="c.type">
                    <div *ngSwitchCase="'text'">
                        <input #input class="custom_input" type="text" [placeholder]="c.placeholder" [formControlName]="c.key"/>
                    </div>
                    <ap-timepicker *ngSwitchCase="'timepicker'" hourTitle="HH" minuteTitle="MM" [styleClass]="'custom-timepicker'" [hideTitleOnInput]="true"
                        [inline]="false" [hourTime]="12" [formControlName]="c.key">
                    </ap-timepicker>
                </td>
                <td>
                    <ap-button type="button" class="d-flex" [label]="'Add'" (onClick)="add(inlineForm.value)" [disabled]="!inlineForm.valid"></ap-button>
                </td>
            </tr>
        </tfoot>
    </table>
</ap-table>
<div class="search" *ngIf="type == 'search'">
    <app-search-field (onSearchEvent)="onSearch($event)" [searchService]="searchService" (onCreateEvent)="showUserModal($event)"></app-search-field>
</div>