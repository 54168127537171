<div class="ap-my-spacing-7" *ngIf="callsheet$ | async as callsheet">
    <div class="row">
        <div class="col-12">
            <div class="d-flex justify-content-end gap-1">
                <ap-button [btnType]="'text'" [icon]="'edit-outline'" [label]="'Back to editing'" (onClick)="preview(callsheet._id!)" *ngIf="!isArchive"></ap-button>
                <ap-button [btnType]="'tertiary'" [icon]="'document-download-outline'" [label]="'Download'" [loading]="true" [isLoading]="isDownloading" (onClick)="download(callsheet._id!)"></ap-button>
            </div>
            <img src="../../../../assets/PwC-logo.svg" alt="PwC logo">
        </div>
    </div>
    <hr class="hr strong">
    <div class="row align-items-center">
        <div class="col-6">
            <h2 appWrapWithUnderscore class="ap-my-spacing-1 ap-py-spacing-1">{{callsheet.projectName}}</h2>
        </div>
        <div class="col-6 d-flex justify-content-end">
            <div class="ap-mr-spacing-8">
                <p class="ap-my-spacing-1">JOB NUMBER</p>
                <strong>#{{callsheet.referenceID}}</strong>
            </div>
            <div>
                <p class="ap-my-spacing-1">SHOOT DATE</p>
                <strong>{{callsheet.shootDate | date: "MM-dd-yyyy"}}</strong>
            </div>
        </div>
    </div>

    <hr class="hr dashed">

    <div class="row">
        <!-- PANEL -->
        <div class="col-12 col-sm-6 col-md-4">
            <app-time-panel [callSheet]="callsheet"></app-time-panel>
        </div>
        <!-- PRODUCERS -->
        <div class="col-6 col-sm-6 col-md-2">
            <p>PRODUCERS</p>
            <div class="d-flex flex-column ap-my-spacing-4">
                <strong>Lead Producer</strong>
                <div *ngFor="let producer of callsheet.producers">
                    <ng-container *ngIf="producer.lead">
                        <p class="ap-my-spacing-1">{{producer.firstName}} {{producer.lastName}} <br>
                            {{producer.email}}<br>
                            {{producer.phone}}
                        </p>
                    </ng-container>
                </div>
                <strong class="ap-mt-spacing-5">Additional Producers</strong>
                <div *ngFor="let producer of callsheet.producers">
                    <ng-container *ngIf="!producer.lead">
                        <p class="ap-my-spacing-1">{{producer.firstName}} {{producer.lastName}}</p>
                    </ng-container>
                </div>
            </div>

        </div>
        <!-- CLIENTS -->
        <div class="col-6 col-sm-4 col-md-2">
            <p class="ap-mb-spacing-4">CLIENTS</p>
            <p>
                <strong *ngFor="let client of callsheet.clients">{{client.firstName}} {{client.lastName}} <br></strong>
            </p>
        </div>
        <!-- VENUE CONTACT -->
        <div class="col-6 col-sm-4 col-md-2">
            <p class="ap-mb-spacing-4">VENUE CONTACT</p>
            <p class="ap-my-spacing-1"><strong>{{callsheet.location.contact.firstName}}
                    {{callsheet.location.contact.lastName}} </strong><br>
                {{callsheet.location.contact.phone}} <br>
                {{callsheet.location.contact.email}}
            </p>
        </div>
        <!-- LOCATION -->
        <div class="col-6 col-sm-4 col-md-2">
            <p class="ap-mb-spacing-4">LOCATION</p>
            <strong>{{callsheet.location.name}}</strong>
            <p class="ap-my-spacing-1">
                {{callsheet.location.firstStreet}}, {{callsheet.location.city}} <br>
                {{callsheet.location.state}}, {{callsheet.location.zipCode}}
            </p>
            <strong>Location Security Office Responsible</strong>
            <p class="ap-my-spacing-1">{{callsheet.location.securityContact}}</p>
            <div *ngIf="callsheet.location.notes">
                <strong>Notes</strong>
                <p class="ap-my-spacing-1">{{callsheet.location.notes}}</p>
            </div>
        </div>

        <div *ngIf="callsheet.notes" class="row ap-mt-spacing-4">
            <div class="col-12">
                <h3>Notes</h3>
                <p>
                    {{callsheet.notes}}
                </p>
            </div>
        </div>
    </div>

    <!-- START TALENT SECTION -->
    <div *ngIf="callsheet.talents.length" class="row ap-my-spacing-4">
        <div class="d-flex justify-content-between">
            <h3>Talent</h3>
        </div>
        <app-panel [actions]="[]" class="col-md-4 col-sm-6" *ngFor="let talent of callsheet.talents">
            <app-business-card class="talentCard" [title]="talent.firstName + ' ' + talent.lastName"
                [items]="getItems(talent)" [notes]="talent.talentNotes" [image]="talent.headshot ? blobUrl + talent.headshot : '/assets/default-avatar.png'"></app-business-card>

            <!-- <div class="row timeTable ap-mt-spacing-4">
                <div class="col-xs-6 col-sm-3">
                    <strong>Arrival</strong>
                    <p class="ap-my-spacing-2">{{(talent.callTime | date: "hh:mm a") || 'TBA'}}</p>
                </div>
                <div class="col-xs-6 col-sm-3">
                    <strong>Makeup</strong>
                    <p class="ap-my-spacing-2">{{(talent.makeupTime | date: "hh:mm a")||'TBA'}}</p>
                </div>
                <div class="col-xs-6 col-sm-3">
                    <strong>Shooting</strong>
                    <p class="ap-my-spacing-2">{{(talent.shootingTime | date: "hh:mm a")||'TBA'}}</p>
                </div>
                <div class="col-xs-6 col-sm-3">
                    <strong>Time out</strong>
                    <p class="ap-my-spacing-2">{{(talent.timeout | date: "hh:mm a") || 'TBA'}}</p>
                </div>
            </div> -->
        </app-panel>

    </div>
    <!-- END TALENT SECTION -->

    <!-- START CREW SECTION -->
    <div class="row ap-my-spacing-4">
        <div class="col-12">
            <h3>Crew</h3>
            <ap-table #table [(originalData)]="callsheet.crewList" [condensed]="true" [striped]="true">
                <table>
                    <thead>
                        <tr>
                            <th>
                                <span>Name</span>
                            </th>
                            <th>
                                <span>Email</span>
                            </th>
                            <th>
                                <span>Phone</span>
                            </th>
                            <th>
                                <span>Arrival Time</span>
                            </th>
                            <th>
                                <span>Notes</span>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let crew of table.data; trackBy: trackId">
                            <td>
                                {{crew.firstName}} {{crew.lastName}}
                            </td>
                            <td>
                                {{crew.email}}
                            </td>
                            <td>
                                {{crew.phone}}
                            </td>
                            <td>
                                {{crew.callTime | date: "hh:mm a" || 'TBA'}}
                            </td>
                            <td style="max-width: 20rem;">
                                {{crew.crewNotes}}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </ap-table>
        </div>
    </div>
    <!-- END CREW SECTION -->

    <!-- START SCHEDULE SECTION -->
    <div class="row ap-my-spacing-4">
        <div class="col-12">
            <h3>Schedule</h3>
            <ap-table #schedule [(originalData)]="callsheet.schedule" [condensed]="true" [striped]="true">
                <table>
                    <thead>
                        <tr>
                            <th>
                                <span>Time</span>
                            </th>
                            <th>
                                <span>Activity</span>
                            </th>
                            <th>
                                <span>Notes</span>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of schedule.data; trackBy: trackId">
                            <td>
                                {{item.time | date: "hh:mm a" || 'TBA'}}
                            </td>
                            <td>
                                {{item.activity}}
                            </td>
                            <td style="max-width: 20rem;">
                                {{item.notes}}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </ap-table>
        </div>
    </div>
    <!-- START SCHEDULE SECTION -->
</div>