import { Injectable } from '@angular/core'
import { Router } from '@angular/router'
import { AuthAdapterService, AuthIdleTimeoutService, AuthImproperLogoutService } from '@rco/angular-auth-extensions'
import { OAuthErrorEvent, OAuthService, TokenResponse } from 'angular-oauth2-oidc'
import { Observable, BehaviorSubject, of, from } from 'rxjs'
import { filter, map, switchMap, take } from 'rxjs/operators'
import { ApplicationUser } from 'src/app/shared/interfaces'

@Injectable({
  providedIn: 'root'
})
export class OauthAdapterService implements AuthAdapterService {
  get isExpired(): boolean {
    return this.idleExpired
  }

  get initials$(): Observable<string> {
    return of(this.oauthService.getIdentityClaims()).pipe(
      filter((res) => res !== null),
      map((claim: any) => {
        const initials = `${claim?.given_name.charAt(0) ? claim?.given_name.charAt(0) : ''}${
          claim?.family_name ? claim?.family_name.charAt(0) : ''
        }`
        return initials
          .replace(/(\r\n|\n|\r)/gm, '')
          .split(' ')
          .join('')
      })
    )
  }

  get user$(): Observable<ApplicationUser> {
    return of(this.oauthService.getIdentityClaims()).pipe(
      filter((res) => res !== null),
      map((claim: Record<string, any>) => {
        const user: ApplicationUser = {
          guid: claim?.['uid'],
          email: claim?.['preferredMail'],
          name: claim?.['name'],
          isPwC: claim?.['preferredMail'].endsWith('pwc.com')
        }
        return user
      })
    )
  }

  private readonly hasValidTokenSubject$ = new BehaviorSubject<boolean>(false)
  private readonly hasValidToken$ = this.hasValidTokenSubject$.asObservable()

  private readonly isDoneLoadingSubject$ = new BehaviorSubject<boolean>(false)
  private readonly isDoneLoading$ = this.isDoneLoadingSubject$.asObservable()

  checkIsAuth$ = this.isDoneLoading$.pipe(
    filter((isDone) => isDone),
    switchMap(() => this.hasValidToken$)
  )

  private isIdleRunning = false
  private idleExpired = false

  constructor(
    private readonly router: Router,
    private readonly oauthService: OAuthService,
    private readonly idleTimeoutService: AuthIdleTimeoutService,
    private readonly authImproperLogoutService: AuthImproperLogoutService
  ) {
    this.initialSetup()
  }

  // dont usually expose this. just for testing purposes.
  getIdentityClaims(): Record<string, any> {
    return this.oauthService.getIdentityClaims()
  }

  login(targetUrl?: string): Observable<void> {
    console.info('OauthAdapterService:login', 'login initiated with url:', targetUrl)
    this.oauthService.initLoginFlow(targetUrl ?? this.router.url, {})
    return of()
  }

  getToken(): Observable<string> {
    const token = this.oauthService.getIdToken()
    return of(token)
  }

  getSyncToken(): string {
    return this.oauthService.getIdToken()
  }

  logout(): Observable<void> {
    const hasValidToken = this.oauthService.hasValidIdToken()
    this.hasValidTokenSubject$.next(hasValidToken)
    if (this.isIdleRunning) {
      this.idleTimeoutService.stop()
    }
    if (hasValidToken) {
      console.info('OauthAdapterService:logout', 'valid token. Calling improper and revoke logout')
      this.authImproperLogoutService.logout(this.oauthService.getIdToken()).subscribe()
      this.oauthService.revokeTokenAndLogout()
    } else {
      console.info('OauthAdapterService:logout', 'invalid token. Navigating to logout')
    }
    this.oauthService.stopAutomaticRefresh()
    this.router.navigate(['/login']) // this will be your logout page
    return of()
  }

  forceRefresh(): Observable<TokenResponse> {
    console.info('OauthAdapterService:forceRefresh', 'force refresh token')
    return from(this.oauthService.refreshToken())
  }

  resetIdleTimeout(): void {
    console.info('OauthAdapterService:resetIdleTimeout', 'resetting idle timeout')
    this.idleTimeoutService.reset()
  }

  async runInitialLoginSequence(): Promise<void> {
    try {
      await this.oauthService.loadDiscoveryDocumentAndTryLogin()
      this.isDoneLoadingSubject$.next(true)
      if (this.oauthService.hasValidIdToken()) {
        if (this.oauthService?.state != null && this.oauthService?.state?.trim() !== '') {
          const url = this.oauthService.state
          await this.router.navigateByUrl(decodeURIComponent(url))
        }
      }
    } catch (error: any) {
      console.error('OauthAdapterService:runInitialLoginSequence', 'error occured', error)
    }
  }

  private initialSetup(): void {
    this.listenToOauthEvents()
    this.oauthService.setupAutomaticSilentRefresh()
    this.checkIsAuth$
      .pipe(
        filter((isAuthenticated) => isAuthenticated),
        take(1)
      )
      .subscribe(() => {
        this.listenToIdleEvents()
        this.listenToStorageEvents()
        this.listenToTabChanges()
      })
  }

  private listenToOauthEvents(): void {
    this.oauthService.events.subscribe((e) => {
      if (e instanceof OAuthErrorEvent) {
        console.error('OauthAdapterService', 'OAuthErrorEvent', e)
      } else {
        console.info('OauthAdapterService', 'OAuthEvent', e)
      }
      if (['token_received'].includes(e.type)) {
        this.oauthService.loadUserProfile().catch((err) => {
          console.error('OauthAdapterService', 'loadUserProfile', err)
        })
      } else if (['invalid_nonce_in_state'].includes(e.type)) {
        setTimeout(() => {
          window.location.href = window.location.origin
        }, 2000) // error is thrown when two instance try to log in at same time. For now just refresh the page
      } else if (['session_terminated', 'session_error'].includes(e.type)) {
        this.logout()
      }
      this.hasValidTokenSubject$.next(this.oauthService.hasValidIdToken())
    })
    this.hasValidTokenSubject$.next(this.oauthService.hasValidIdToken())
  }

  private listenToStorageEvents(): void {
    window.addEventListener('storage', (event) => {
      if (event.key !== 'access_token' && event.key !== null) {
        return
      }
      const hasValidToken = this.oauthService.hasValidIdToken()
      console.warn('OauthAdapterService', 'Noticed changes to access_token, updating isAuthenticated')
      if (!hasValidToken) {
        this.logout()
      }
      this.hasValidTokenSubject$.next(hasValidToken)
    })
  }

  private listenToTabChanges(): void {
    document.addEventListener('visibilitychange', (_) => {
      const hasValidToken = this.oauthService.hasValidIdToken()
      console.info('OauthAdapterService:listenToTabChanges', 'detected visibility change, has valid token:', hasValidToken)
      this.hasValidTokenSubject$.next(hasValidToken)
      if (!hasValidToken) {
        this.logout()
      }
    })
  }

  private listenToIdleEvents(): void {
    console.info('OauthAdapterService:listenToIdleEvents', 'start idle timeout')
    this.idleTimeoutService.start()
    this.isIdleRunning = true
    this.idleTimeoutService.onTimeout().subscribe((res: any) => {
      if (res) {
        this.idleExpired = true
        console.info('OauthAdapterService:listenToIdleEvents', 'idle timeout reached, logging out.', res)
        this.logout()
      }
    })
  }
}
