<div *ngIf="callsheet$ | async as callsheet">
    <div class="row ap-my-spacing-7">
        <div class="col-12 ap-pb-spacing-8">
            <p>Clone call sheet</p>
            <h2>First, select the corresponding workfront project</h2>
        </div>
        <div class="col-12 col-sm-6 col-md-4 ap-mb-spacing-6 search-container">
            <p>Search project by reference number</p>
            <ap-field [title]="'Project reference number'" [error]="invalidId && !loading || inputError" [type]="'appkit-number'"
                [format]="''">
                <input appkit-field [(ngModel)]="projectRefNumber" (input)="checkInput()" />
                <ap-loading [loadingType]="'circular'" [indeterminate]="true" *ngIf="loading"
                    [circularWidth]="'21px'"></ap-loading>
                <span *ngIf="invalidId == true && !loading"
                    class="icon error Appkit4-icon icon-circle-delete-outline"></span>
                <span *ngIf="invalidId == false && !loading"
                    class="icon success Appkit4-icon icon-circle-checkmark-outline"></span>
            </ap-field>
        </div>

        <div class="col-12 col-sm-6 col-md-4 ap-mb-spacing-6 btn-wrapper">
            <ap-button [btnType]="'primary'" [label]="'Search'"
                [disabled]="!projectRefNumber || loading || inputError" (onClick)="searchProject()">
            </ap-button>
        </div>
        <p *ngIf="invalidId && !loading" class="error">
            We can't find a match. Please make sure the reference number is correct.
        </p>
        <p *ngIf="inputError" class="error">
            The input should only contain numbers.
        </p>
        <p *ngIf="invalidId == false &&  !loading" class="success">
            ID matched
        </p>

        <div *ngIf="invalidId == false && !loading" class="col-12 col-sm-6 col-md-4 ap-mb-spacing-6">
            <ap-field [title]="'Project name'" [readonly]="true">
                <input appkit-field [(ngModel)]="projectName" aria-label="Project name" />
            </ap-field>

        </div>
        <div *ngIf="invalidId == false && !loading" class="col-12 col-sm-6 col-md-4 ap-mb-spacing-6">
            <ap-field [title]="'Shoot date'" [datepicker]="true" [dpType]="'single'" [dpRange]="false"
                [(dpSelectedDates)]="shootDate" [dpMinDate]="minDate">
                <input appkit-field />
            </ap-field>
        </div>
    </div>

    <div *ngIf="invalidId == false && !loading" class="d-flex justify-content-end gap-2">
        <ap-button type="button" [btnType]="'secondary'" [label]="'Select sections to clone'" [icon]="'duplicate-outline'"
        [disabled]="!shootDate.length" (onClick)="selectSectionsToClone()"></ap-button>
        <ap-button type="submit" [btnType]="'primary'" [label]="'Clone whole call sheet'" [icon]="'duplicate-outline'"
            [disabled]="!shootDate.length" (onClick)="cloneWholeCallsheet()"></ap-button>
    </div>
</div>