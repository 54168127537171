import { Component } from '@angular/core';
import { ActionButton, ButtonDropdownOptions, CallSheet } from 'src/app/shared/interfaces';
import { OauthAdapterService } from 'src/app/core/oauth';
import { BehaviorSubject, Observable, distinctUntilChanged, map, switchMap } from 'rxjs';
import { CallsheetsService } from '../../callsheets.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-list-callsheet',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss']
})
export class ListCallsheetComponent {

  feature: number = 2
  totalPages$: Observable<number>
  itemsPerPage: number = 15
  page$ = new BehaviorSubject<number>(1)
  callsheets$!: Observable<CallSheet[]>
  showProjectUrl: boolean = false

  actionsPanel: ActionButton[] = [
    {
      icon: 'Appkit4-icon icon-edit-outline',
      action: 'edit',
      label: 'Edit',
      type: ''
    }
  ]

  actionsList: ActionButton[] = [
    {
      icon: 'Appkit4-icon icon-edit-outline',
      action: 'edit',
      label: 'Edit',
      type: ''
    },
    {
      icon: 'Appkit4-icon icon-view-outline',
      action: 'preview',
      label: 'Preview',
      type: ''
    }
  ]

  moreOptionsPanel: ButtonDropdownOptions[] = []

  moreOptionsList: ButtonDropdownOptions[] = []

  constructor(private readonly authService: OauthAdapterService, private callsheetsService: CallsheetsService, private router: Router) {
    this.totalPages$ = this.callsheetsService.totalActive$.asObservable().pipe(map(x => !!x ? Math.ceil((x - this.feature) / this.itemsPerPage) : 0))
    this.authService.user$.subscribe(x => {
      if (x.isPwC) {
        this.showProjectUrl = true

        this.actionsPanel.push({ icon: 'Appkit4-icon icon-download-light-outline', action: 'download', label: 'Download', type: '' })

        this.moreOptionsPanel.push(
          { label: "Clone this call sheet", value: "clone", icon: "Appkit4-icon icon-duplicate-outline" },
          { label: "Copy public link to clipboard", value: "share", icon: "Appkit4-icon icon-link-outline" },
          { label: "Delete call sheet", value: "delete", icon: "Appkit4-icon icon-delete-outline" }
        )

        this.moreOptionsList.push(
          { label: "Download PDF", value: "download", icon: "Appkit4-icon icon-download-light-outline" },
          { label: "Clone this call sheet", value: "clone", icon: "Appkit4-icon icon-duplicate-outline" },
          { label: "Copy public link to clipboard", value: "share", icon: "Appkit4-icon icon-link-outline" },
          { label: "Delete call sheet", value: "delete", icon: "Appkit4-icon icon-delete-outline" },
        )
      } else {
        this.moreOptionsList.push({ label: "Copy public link to clipboard", value: "share", icon: "Appkit4-icon icon-link-outline" })
        this.moreOptionsPanel.push({ label: "Copy public link to clipboard", value: "share", icon: "Appkit4-icon icon-link-outline" })
      }
    })
  }

  ngOnInit() {
    this.callsheets$ = this.page$.pipe(
      distinctUntilChanged(),
      switchMap((page: number) => {
        const limit = page == 1 ? this.itemsPerPage + this.feature : this.itemsPerPage
        return this.callsheetsService.search({
          ...((page !== 1 && this.feature) && { skip: this.feature }),
          limit,
          page: page - 1
        })
      })
    )
  }

  handleActions(event: { action: string, callsheetId: string }) {
    switch (event.action) {
      case "edit":
        this.router.navigate(['/callsheet', event.callsheetId])
        break;
      case "delete":
        this.callsheetsService.delete({ _id: event.callsheetId } as CallSheet)
        break;
      case "preview":
        this.router.navigate(['/callsheet/view/', event.callsheetId])
        break;
      case "clone":
        this.router.navigate(['/callsheet/clone', event.callsheetId])
        break;
      default:
        break;
    }
  }

  onPageChange(page: number) {
    this.page$.next(page)
  }
}
