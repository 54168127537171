import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { NavigationItem } from "@appkit4/angular-components/navigation";
import { CustomNavigationItem } from '../../interfaces/custom-navigation-item.interface';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {
  
  selectedIndex: number = 0;

  @Input()
  collapse: boolean = false

  @Input()
  scroll: boolean = false

  @Input()
  navList: Array<NavigationItem> = []

  @Input()
  biggerWidth: boolean = false

  @Output()
  onCollapseEvent = new EventEmitter<boolean>()

  @Output()
  onScrollEvent = new EventEmitter<any>()

  constructor(private router: Router) {}

  ngOnInit() {}

  redirect(item: CustomNavigationItem, scroll:boolean) {    
    if(scroll) {
      this.onScrollEvent.emit(item.route)
    } else {
      this.router.navigate([item.route]);
    }
  }

  onCollapsedItems($event: any){
    localStorage.setItem('sidebar-collapsed', $event.status)
    this.onCollapseEvent.emit($event.status)
  }

}
