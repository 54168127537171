<div class="custom-search">
    <div class="d-flex align-items-center">
        <ap-search [placeholder]="placeholder" [formControl]="control" [searchType]="'secondary'"
            (onClear)="onClear()"></ap-search>
        <ap-loading [loadingType]="'circular'" [indeterminate]="true" *ngIf="loading" [circularWidth]="'21px'"></ap-loading>
    </div>
    <div [hidden]="hidden" class="search-result">
        <div class='list-container'>
            <ap-list [bordered]="true" [style]="'width:315px'">
                <div *ngIf="!data.length">
                    <div class="d-flex justify-content-center ap-p-spacing-5">{{noResultMsg}}</div>
                    <ap-button *ngIf="searchService == 'user'" [style]="'width: 100%;'" [btnType]="'secondary'" type="button" [label]="'Create user'" (onClick)="onCreate()" ></ap-button>
                </div>
                <ap-list-item *ngFor="let item of data" [attr.role]="'option'" (click)="onListItemClick(item)"
                    (keyup.enter)="onListItemClick(item)">
                    <ap-list-item-meta *ngIf="searchService !== 'vendor'">
                        <ap-list-item-meta-avatar>
                            <div class="ap-list-avatar-container">
                                <img [src]="item.headshot ? blobUrl + item.headshot : '/assets/default-avatar.png'" alt="avatar">
                            </div>
                        </ap-list-item-meta-avatar>
                        <ap-list-item-meta-title>{{item.firstName}} {{ item.lastName}}</ap-list-item-meta-title>
                        <ap-list-item-meta-description>
                            <span>{{ item.email }}</span>
                        </ap-list-item-meta-description>
                    </ap-list-item-meta>
                    <ap-list-item-meta *ngIf="searchService === 'vendor'">
                        <ap-list-item-meta-title>{{item.companyName}}</ap-list-item-meta-title>
                        <ap-list-item-meta-description>
                            <span>{{ item.email }}</span>
                        </ap-list-item-meta-description>
                    </ap-list-item-meta>
                </ap-list-item>
            </ap-list>
        </div>
    </div>
</div>
<div [hidden]="hidden" class="custom-search-backdrop" (click)="clickOutside()"></div>