import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, catchError, tap, throwError } from 'rxjs';
import { OauthAdapterService } from 'src/app/core/oauth';
import { ApplicationUser } from 'src/app/shared/interfaces';
import { CallsheetsService } from '../../callsheets.service';

@Component({
  selector: 'app-create-callsheet',
  templateUrl: './create.component.html',
  styleUrls: ['./create.component.scss']
})
export class CreateCallsheetComponent implements OnInit {

  callSheetId: string = ''
  user$: Observable<ApplicationUser>
  isDownloading: boolean = false
  callsheetRefNumber!: string

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private readonly authService: OauthAdapterService,
    private readonly callsheetsService: CallsheetsService
  ) {
    this.user$ = this.authService.user$
  }

  ngOnInit() {
    this.callSheetId = this.route.snapshot.params['id']
    this.callsheetsService.getById(this.callSheetId).subscribe(x => this.callsheetRefNumber = x.referenceID)
  }

  preview() {
    this.router.navigate(['/callsheet/view/', this.callSheetId])
  }

  download() {
    this.isDownloading = true
    this.callsheetsService.generatePDF(this.callSheetId).pipe(
      tap(x => {
        let blob = new Blob([x], { type: "application/pdf" });
        let url = window.URL.createObjectURL(blob);
        let a = document.createElement("a");
        document.body.appendChild(a);
        a.href = url;
        a.download = `PwC callsheet ${this.callsheetRefNumber}`;
        a.click();
        a.remove();
        this.isDownloading = false
      }),
      catchError((e) => {
        this.isDownloading = false
        return throwError(() => e)
      })
    ).subscribe()
  }
}
