<ap-panel [bordered]='true' [title]="''" class="ap-p-spacing-5 ap-mb-spacing-5">
    <ng-content></ng-content>
    <div *ngIf="actions" class="d-flex justify-content-end">
        <ng-container *ngFor="let action of actions">
            <ap-button [btnType]="'text'" [icon]="action.icon!" (onClick)="onClick(action)" ap-tooltip
                [tooltipContent]="action.label!" [direction]="'top'" class="icon"></ap-button>
        </ng-container>
        <ap-menu-button *ngIf="moreOptions" [icon]="'vertical-more-outline'" [buttonData]="moreOptions" class="d-flex justify-content-end align-items-center icon" [compact]="true" >
            <ap-dropdown-list-item *ngFor="let item of moreOptions" [item]="item" (onSelectItem)="onSelectOption($event)" class="more-options">
                <span [class]="item.icon"></span>
            </ap-dropdown-list-item>
        </ap-menu-button>
    </div>
</ap-panel>