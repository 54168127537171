<div class="row">
    <div class="col-8" *ngIf="pwcUser">
        <h3>Getting started - Create a call sheet</h3>
        <p>
            Create a call sheet from scratch or import from an existing document. You will start this process from the
            corresponding Workfront project, by selecting a date and location. Next, input all necessary shoot details,
            such as talents, client, vendors and a tentative schedule just by clicking on a field and start typing. You
            will see that some of these fields can be automatically populated as long as the information has already
            been uploaded to the call sheets system.
        </p>
        <p>
            If this is your first time using Call Sheets, manual entry of vendors, locations, or users details may be
            necessary if they are not part of the PMDM database. You can quickly do this from their corresponding pages
            on the menu, Call Sheets will store this information for you, so you’ll only have to do it once.
        </p>
        <p>
            <strong>Using Call Sheets from Workfront:</strong> your documents will be organized within their respective
            projects, allowing users to edit, create, delete, share, preview, and download call sheets.
        </p>
        <p>
            <strong>Using Call Sheets from the web application:</strong> these
            functionalities remain the same. The site will list all of your call sheets, giving priority to your
            upcoming shoots. To refer back to previous call sheets, navigate to the archive page. And to upload or
            update information (new vendors, locations and/or users) click on their dedicated pages in the left-hand
            menu. 
        </p>
        <p>
            For a detailed, step-by-step tutorial on getting started, please watch the accompanying video.
        </p>
        <div>
            <video controls class="w-100">
                <source src="../../../assets/video-tutorials/create_callsheet.mov" type="video/mp4" />

                Your browser does not support the video tag.
            </video>
        </div>

    </div>
    <div class="col-8" *ngIf="!pwcUser">
        <h3>Getting started</h3>
        <p>
            As a vendor, you will be sent a link via email after a Video Team member has assigned you to a project. The
            Call Sheets application will open in a view specially designed for your use, with a home and a “crew” page,
            and displaying all your assigned projects.
        </p>
        <p>
            Select a call sheet to review and proceed to assign the requested crew and equipment, specified by the Video
            Team on the document.
        </p>
        <p>
            If this is your first time using Call Sheets, you will have to enter your team’s basic information from the
            “Crew” page on the left-side menu. The system automates the process of manually filling out this data to
            every call sheet you are assigned to, so you will only have to do this once. This information is later sent
            to the security team at PwC, as they keep an updated list of all the staff and collaborators of the shoot,
            to manage access to the building through the Call Sheets platform
        </p>
        <p>
            <strong>Using Call Sheets from the web application:</strong> these
            functionalities remain the same. The site will list all of your call sheets, giving priority to your
            upcoming shoots. To refer back to previous call sheets, navigate to the archive page. And to upload or
            update information (new vendors, locations and/or users) click on their dedicated pages in the left-hand
            menu. 
        </p>
        <p>
            For a detailed, step-by-step tutorial on getting started, please watch the accompanying video. </p>
            <div>
                <video controls class="w-100">
                    <source src="../../../assets/video-tutorials/vendors_tutorial.mov" type="video/mp4" />
    
                    Your browser does not support the video tag.
                </video>
            </div>
    </div>
    <hr class="ap-my-spacing-7">
</div>