<ap-search #search [placeholder]="placeholder" [searchType]="'secondary'" [filtered]="true" [list]="list"
    [noResultMsg]="noResultMsg" (onSearch)="onGlobalSearch($event)" (onClear)="onClear($event)">

    <ng-container *ngFor="let item of list">
        <ap-dropdown-list-item #dropdownListItem [item]="item" [highlightText]="searchText"
            (onSelectItem)="onGlobalSelectItem($event)" (onMouseEnter)="onMouseEnterItem($event)"
            (onMouseLeave)="onMouseLeaveItem($event)">
            <span class="Appkit4-icon
            icon-arrow-increase-small-outline"></span>
        </ap-dropdown-list-item>
    </ng-container>
</ap-search>