import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FieldModule } from '@appkit4/angular-components/field';
import { ButtonModule } from '@appkit4/angular-components/button';
import { TableModule } from '@appkit4/angular-components/table';
import { ListModule } from '@appkit4/angular-components/list';
import { DropdownModule } from '@appkit4/angular-components/dropdown';
import { CheckboxModule } from '@appkit4/angular-components/checkbox';
import { ComboboxModule } from '@appkit4/angular-components/combobox';
import { SearchModule } from '@appkit4/angular-components/search';
import { NavigationModule } from '@appkit4/angular-components/navigation';
import { TimepickerModule } from '@appkit4/angular-components/timepicker';
import { DropdownListItemModule } from '@appkit4/angular-components/dropdown-list-item';
import { ToggleModule} from '@appkit4/angular-components/toggle';
import { SearchComponent } from './components/search/search.component';
import { HeaderPageComponent } from './components/header-page/header-page.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { BusinessCard } from './components/business-card/business-card.component';
import { ConfirmModalComponent } from './components/confirm-modal/confirm-modal.component';
import { ModalModule } from '@appkit4/angular-components/modal';
import { PanelComponent } from './components/panel/panel.component';
import { PanelModule } from '@appkit4/angular-components/panel';
import { LoadingComponent, LoadingModule } from '@appkit4/angular-components/loading';
import { TooltipModule } from '@appkit4/angular-components/tooltip';
import { UserFormModalComponent } from './components/user-form-modal/user-form-modal.component';
import { SkeletonDirective } from './directives/skeleton/skeleton.directive';
import { RectComponent } from './directives/skeleton/types/rect/rect.component';
import { UserInfoComponent } from './components/user-info/user-info.component';
import { BadgeModule } from '@appkit4/angular-components/badge';
import { RoleListComponent } from './components/role-list/role-list.component';


@NgModule({
  declarations: [
    SearchComponent,
    HeaderPageComponent,
    SidebarComponent,
    BusinessCard,
    ConfirmModalComponent,
    PanelComponent,
    UserFormModalComponent,
    SkeletonDirective,
    RectComponent,
    UserInfoComponent,
    RoleListComponent,
  ],
  imports: [
    CommonModule,
    ButtonModule,
    RouterModule,
    TableModule,
    ListModule,
    FieldModule,
    FormsModule,
    ReactiveFormsModule,
    TimepickerModule,
    DropdownModule,
    CheckboxModule,
    ComboboxModule,
    SearchModule,
    NavigationModule,
    DropdownListItemModule,
    ModalModule,
    PanelModule,
    LoadingModule,
    TooltipModule,
    BadgeModule,
    ToggleModule
  ],
  exports: [
    SearchComponent,
    HeaderPageComponent,
    SidebarComponent,
    BusinessCard,
    ConfirmModalComponent,
    PanelComponent,
    LoadingComponent,
    UserFormModalComponent,
    SkeletonDirective,
    UserInfoComponent,
    RoleListComponent,
    ButtonModule,
    FieldModule,
    TooltipModule,
    ModalModule,
    DropdownModule,
    ComboboxModule,
    CheckboxModule,
    BadgeModule,
    ToggleModule,
  ],
  providers: [],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class SharedModule { }
