import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/env';

@Component({
  selector: 'app-confirm',
  templateUrl: './confirm.component.html',
  styleUrls: ['./confirm.component.scss']
})
export class ConfirmComponent implements OnInit {

  result$!: Observable<boolean>
  constructor(private httpService: HttpClient, private route: ActivatedRoute) { }

  ngOnInit() {
    const { id } = this.route.snapshot.params
    let [callsheetId, userId] = atob(id).split('.')
    this.result$ = this.httpService.post<boolean>(`${environment.apiUrl}/callsheets/${callsheetId}/receipt/${userId}`, {})
  }
}