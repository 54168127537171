import { Component, Input } from '@angular/core';
import { CallSheet } from '../../../../shared/interfaces/callsheet.interface';

@Component({
  selector: 'app-time-panel',
  templateUrl: './time-panel.component.html',
  styleUrls: ['./time-panel.component.scss']
})
export class TimePanelComponent {

  @Input() callSheet!: CallSheet
}
