import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { debounceTime, distinctUntilChanged, map, switchMap } from 'rxjs';
import { User } from 'src/app/shared/interfaces/callsheet.interface';
import { UsersService } from 'src/app/main/users/users.service';
import { VendorsService } from 'src/app/main/vendors/vendors.service';
import { environment } from 'src/environments/env';


@Component({
  selector: 'app-search-field',
  templateUrl: './search-field.component.html',
  styleUrls: ['./search-field.component.scss']
})
export class SearchFieldComponent {

  control = new FormControl('')
  hidden: boolean = true
  loading: boolean = false
  data: any[] = []
  blobUrl: string = environment.assetsUrl

  @Output()
  onSearchEvent = new EventEmitter<User>()

  @Output()
  onCreateEvent = new EventEmitter()

  @Input()
  btnLabel: string = 'New'

  @Input()
  placeholder: string = 'Start typing...'

  @Input()
  noResultMsg: string = 'Nothing matches your keywords'

  @Input()
  searchService: 'user' | 'vendor' | 'employees' = 'user'

  constructor(
    private readonly userService: UsersService,
    private readonly vendorService: VendorsService) { }

  onListItemClick(item: any) {
    this.control.setValue('')
    this.hidden = true
    this.onSearchEvent.emit(item)
  }

  onClear() {
    this.data = [];
  }

  ngAfterViewInit() {
    this.control.valueChanges
      .pipe(
        debounceTime(800),
        distinctUntilChanged(),
        switchMap((text) => {
          if (!text) return []
          this.loading = true
          return this.search(text)
        })
      )
      .subscribe(
        (result: User[]) => {
          this.data = result.map(u => {
            const { isProducer: _, ...newObj } = u
            return newObj
          })
          this.loading = false
          this.hidden = false
        }
      )
  }

  search(text: string) {
    switch (this.searchService) {
      case 'user':
      case 'employees':
        return this.userService.search(text)
        break;
      case 'vendor':
        return this.vendorService.search(text)
        break;
      default:
        return []
        break;
    }
  }

  onCreate(){
    this.clickOutside()
    this.onCreateEvent.emit()
  }

  clickOutside() {
    this.hidden = true
  }
}