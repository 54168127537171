import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { debounceTime, distinctUntilChanged, take } from 'rxjs';
import { UsersService } from 'src/app/main/users/users.service';
import { User } from 'src/app/shared/interfaces';
import { environment } from 'src/environments/env';
import { EMAIL_PATTERN, PHONE_PATTERN } from '../../shared.constants';
import { CustomNotificationService } from '../../services/notification.service';

@Component({
  selector: 'app-user-form-modal',
  templateUrl: './user-form-modal.component.html',
  styleUrls: ['./user-form-modal.component.scss']
})
export class UserFormModalComponent implements OnInit {

  @ViewChild('modal', { static: false }) modal: any;
  style: string = 'width: 540px;';
  contentStyle: string = 'min-height: 92px; overflow: unset;';
  isNew: boolean = false
  maxImageByteSize: number = 500000
  userForm!: FormGroup
  user: User = {} as User
  headshot: File = new File([], '')
  defaultAvatar: string = '/assets/default-avatar.png'
  assetsUrl: string = environment.assetsUrl
  updatedUser: Partial<User> = {}

  @Input() objectType: string = 'user'
  @Input() modalId!: string

  @Output()
  onSubmitEvent = new EventEmitter<User>()

  sharedFields = {
    _id: [''],
    headshot: [''],
    firstName: new FormControl('', [Validators.required]),
    lastName: new FormControl('', [Validators.required]),
    email: ['', [Validators.required, Validators.email, Validators.pattern(EMAIL_PATTERN)]],
    phone: ['', [Validators.pattern(PHONE_PATTERN)]],
    notes: [''],
  }

  constructor(private fb: FormBuilder, private readonly usersService: UsersService, private readonly notificationService: CustomNotificationService) { }

  ngOnInit(): void {
    let fields = {}
    if (this.objectType === 'user') {
      fields = {
        isProducer: new FormControl(false)
      }
    } else if (this.objectType === 'crew') {
      fields = {
        roles: new FormControl([[]])
      }
    }

    this.userForm = this.fb.group({
      ...this.sharedFields,
      ...fields
    })

    this.userForm.valueChanges.pipe(
      debounceTime(400),
      distinctUntilChanged()
    ).subscribe(changes => {
      this.updatedUser = {}
      for (const key in changes) {
        if (changes[key] !== this.user[key]) {
          this.updatedUser[key] = changes[key];
        }
      }
    })    
  }

  submitForm() {      
    const payload: User = {
      _id: this.userForm.value._id
    } as User

    const headshot = this.headshot.size && this.headshot || undefined

    if (payload._id) {
      this.usersService.update({ ...payload, ...this.updatedUser }, headshot).pipe(
        take(1)
      ).subscribe((user: User) => {
        this.onSubmitEvent.emit(user)
      })
    } else {
      this.usersService.create({ ...payload, ...this.userForm.value }, headshot).pipe(
        take(1)
      ).subscribe((user: User) => {
        this.onSubmitEvent.emit(user)
      })
    }
    this.onClose()
  }

  onClose() {
    this.defaultAvatar = '/assets/default-avatar.png'
    this.userForm.reset({
      _id: '',
      firstName: '',
      lastName: '',
      headshot: '',
      email: '',
      phone: '',
      notes: '',
      roles:[]
    })
    this.headshot = new File([], '')
    this.modal.closeModal(`user-form-modal-${this.modalId}`)
  }

  show(user: User) {
    this.isNew = Object.keys(user).length === 0
    this.user = { ...user }
    this.userForm.patchValue(user)
    this.modal.showModal(`user-form-modal-${this.modalId}`)
  }

  removeAvatar() {
    this.userForm.patchValue({ headshot: '' })
  }

  onFileChange($event: any) {
    if (!$event.target.files.length) return

    if ($event.target.files[0].size < this.maxImageByteSize) {
      this.headshot = $event.target.files[0]
      this.defaultAvatar = URL.createObjectURL(this.headshot)
      this.removeAvatar()
    } else {
      this.notificationService.error('Headshot image size should be less than 500KB.')
    }
  }

  onRowChange(selectedRoles: string[]) {
    console.log(this.userForm.value);
  }
}