import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable, catchError, map, switchMap } from 'rxjs';
import { OauthAdapterService } from 'src/app/core/oauth';
import { CallsheetsService } from 'src/app/main/callsheets/callsheets.service';
import { CallSheet } from 'src/app/shared/interfaces';
import { environment } from 'src/environments/env';

@Component({
  selector: 'app-public-preview',
  templateUrl: './public-preview.component.html',
  styleUrls: ['./public-preview.component.scss']
})
export class PublicPreviewComponent {

  constructor(private callsheetsService: CallsheetsService, private route: ActivatedRoute, private readonly authService: OauthAdapterService) { }

  callsheet$!: Observable<CallSheet>
  blobUrl: string = environment.assetsUrl
  isArchive: boolean = false

  async ngOnInit() {
    this.callsheet$ = this.route.params.pipe(
      switchMap((params: any) => {
        return this.callsheetsService.getPublicView(params.id)
          .pipe(
            catchError(err => {
              throw err
            }),
            map((c: { isArchive: boolean, callsheet: CallSheet }) => {
              this.isArchive = c.isArchive
              return c.callsheet
            }))
      })
    )
  }

  onPwCSSOLogin($event: any) {
    this.authService.login('/')
  }


}
