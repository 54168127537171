import { Injectable } from "@angular/core";
import { DateTime } from 'luxon'

@Injectable({
    providedIn: 'root'
})
export class HelperService {
    constructor() { }

    getLocationTimezoneDate(date: Date | string | undefined, offset: number) {
        if (!date) return undefined

        if (date instanceof Date) {
            date = date.toISOString()
        }
        return new Date(DateTime.fromISO(date.toString().slice(0, -5)).plus({ hours: offset }).toString())
    }

    parseTimezoneDate(date: Date, offset: number) {
        if (!date) return undefined
        const local = DateTime.fromJSDate(date)
        return new Date(DateTime.fromISO(local.toString().slice(0, -10), { zone: `UTC${offset}` }).toString())
    }
}