<ap-header [type]="'transparent'" (onClickLogo)="goToHome()">
    <ng-template ngTemplate="title">
        Callsheets
    </ng-template>
    <ng-template ngTemplate="options">
        <a [href]="supportUrl" target="_blank" rel="noopener noreferrer">
            <ap-header-options-item [iconName]="'help-question-outline'" [label]="'Support'">
            </ap-header-options-item>
        </a>
    </ng-template>
    <ng-template ngTemplate="user">
        <ap-avatar [name]="(initials$ | async)!" borderWidth="0" diameter="40" [role]="'button'" [list]="avatarList"
            [withDropdown]="true">
            <ng-template ngTemplate="dropdownTemp">
                <p class="user-name">{{user.name}}</p>
                <hr class="hr profile">
                <ng-container *ngFor="let item of avatarList; let i = index;">
                    <ap-dropdown-list-item #dropdownListItem [item]="item" (onSelectItem)="onSelectItem($event)">
                        <ng-template *ngIf="item.iconName" ngTemplate="prefixTemp">
                            <span class="Appkit4-icon icon-{{item.iconName}}"></span>
                        </ng-template>
                    </ap-dropdown-list-item>
                </ng-container>
            </ng-template>
        </ap-avatar>
    </ng-template>
</ap-header>
<div class="page-body">
    <div class="page-sidebar">
        <app-sidebar [navList]="navList" [collapse]="isSidebarCollapsed" (onCollapseEvent)="isSidebarCollapsed = $event"
            (onScrollEvent)="scrollToSection($event)" [scroll]="true" [biggerWidth]="true">
        </app-sidebar>
    </div>
    <div class="page-content">
        <div class="ap-container page-title">
            <div class="ap-p-spacing-8">
                <h5>Help page</h5>
                <h2 class="col-6">How to use PwC’s Call Sheets</h2>
            </div>
        </div>

        <div class="ap-container">
            <div class="row ap-p-spacing-8">
                <div class="col-8">
                    <h3>About</h3>
                    <p>
                        Video Call sheets is a web application designed for PwC’s Video Team in the U.S, meant to
                        simplify the call
                        sheet
                        creation process. View and edit documents directly from your Workfront projects or from its
                        dedicated
                        website,
                        designed for a more comfortable user experience.
                    </p>
                    <p>
                        In this page you can find video tutorials and information about all the application’s
                        functionalities.
                    </p>
                </div>
                <hr class="ap-my-spacing-7">
                <div *ngIf="user.isPwC">
                    <div id="overview">
                        <app-overview [pwcUser]="true"></app-overview>
                    </div>
                    <div id="vendors-location-users-archive">
                        <app-vendors></app-vendors>
                    </div>
                    <div id="clone-import">
                        <app-clone-import></app-clone-import>
                    </div>
                </div>
                <div *ngIf="!user.isPwC">
                    <div id="overview">
                        <app-overview [pwcUser]="false"></app-overview>
                    </div>
                </div>
                <div class="ap-py-spacing-4 d-flex justify-content-end">
                    <ap-button type="button" (onClick)="goToTop()" [btnType]="'secondary'"
                        [label]="'Go to top'"></ap-button>
                </div>
            </div>
        </div>
    </div>

</div>
<ap-footer [content]="footerContent" [type]="footerType" [hidden]="false"></ap-footer>