import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core';
import { ActionButton, ButtonDropdownOptions, CallSheet } from '../../../../shared/interfaces';
import { environment } from 'src/environments/env';
import { ConfirmModalComponent } from '../../../../shared/components/confirm-modal/confirm-modal.component';
import { CallsheetsService } from '../../callsheets.service';
import { catchError, tap, throwError } from 'rxjs';
import { CustomNotificationService } from 'src/app/shared/services/notification.service';

@Component({
  selector: 'app-callsheets-table',
  templateUrl: './callsheets-table.component.html',
  styleUrls: ['./callsheets-table.component.scss']
})
export class CallsheetsTableComponent implements OnChanges {

  selectedId!: string
  featuresCallsheets!: CallSheet[]
  currentPage: number = 1
  workfrontUrl: string = environment.workfrontUrl

  selectedRow!: number

  @ViewChild(ConfirmModalComponent) confirmModalComponent!: ConfirmModalComponent;

  @Input() itemsPerPage: number = 1
  @Input() totalPages: number = -1
  @Input() actionsPanel: ActionButton[] = []
  @Input() actionsList: ActionButton[] = []
  @Input() feature!: number
  @Input() callsheets!: CallSheet[] | null
  @Input() showProjectUrl: boolean = false
  @Input() moreOptionsPanel!: ButtonDropdownOptions[];
  @Input() moreOptionsList!: ButtonDropdownOptions[];
  @Input() archiveCallsheets: boolean = false


  @Output() onPageChange = new EventEmitter<number>()
  @Output() onAction = new EventEmitter<{ action: string, callsheetId: string, index?: number }>()

  ngOnChanges(changes: SimpleChanges) {
    if (changes['callsheets']?.currentValue && this.currentPage === 1) {
      this.featuresCallsheets = this.callsheets!.splice(0, this.feature)
    }
  }

  constructor(private callsheetsService: CallsheetsService, private readonly notificationService: CustomNotificationService) { }

  handleActions(action: string, callsheet: CallSheet, index?: number) {
    switch (action) {
      case "edit":
      case "preview":
      case "clone":
        this.onAction.emit({ action, callsheetId: callsheet._id! })
        break;
      case "select":
        this.selectedRow = index!;
        this.onAction.emit({ action, callsheetId: callsheet._id! })
        break;
      case "delete":
        this.selectedId = callsheet._id!
        this.confirmModalComponent.show()
        break;
      case "share":
        let publicLink = `${window.location.host}/public/${callsheet._id}`
        navigator.clipboard.writeText(publicLink).then(() => {
          this.notificationService.success('Public link copied to clipboard successfully')
        })
          .catch((error) => {
            this.notificationService.error('Unable to copy text to clipboard')
          });
        break;
      case "download":
        this.callsheetsService.generatePDF(callsheet._id!).pipe(
          tap(x => {
            let blob = new Blob([x], { type: "application/pdf" });
            let url = window.URL.createObjectURL(blob);
            let a = document.createElement("a");
            document.body.appendChild(a);
            a.href = url;
            a.download = `PwC callsheet ${callsheet.referenceID}`;
            a.click();
            a.remove();
          }),
          catchError((e) => {
            return throwError(() => e)
          })
        ).subscribe()
        break;
      default:
        break;
    }
  }

  trackId(index: number, item: any) {
    return item.order;
  }

  onPaginateChange($event: any) {
    if ($event != 0) {
      this.currentPage = $event
      this.onPageChange.emit($event)
    }
  }

  delete() {
    this.callsheets = null
    this.onAction.emit({ action: "delete", callsheetId: this.selectedId })
  }

}
