import { Injectable } from "@angular/core";
import { CallSheet, Producer, User } from "../../shared/interfaces/callsheet.interface";
import { HttpClient } from "@angular/common/http";
import { BehaviorSubject, Subject, catchError, map, of, scan, shareReplay, tap, throwError } from "rxjs";
import { environment } from "src/environments/env";
import { CustomNotificationService } from "src/app/shared/services/notification.service";
import { CustomDropdownItem } from "src/app/shared/interfaces";

interface UserStateAction {
    action: "init" | "add" | "remove" | "update" | "filter"
    payload: User
}

@Injectable({
    providedIn: 'root'
})
export class UsersService {

    private users: User[] = []
    private filtered: User[] = []
    private searchText: string = ''
    private action$ = new Subject<UserStateAction>()
    private producers$ = new BehaviorSubject<CustomDropdownItem<Producer>[] | null>(null)

    users$ = this.action$.pipe(
        //startWith({ action: 'init', payload: {} as User } as UserStateAction),
        scan((state: User[], action: UserStateAction) => {
            switch (action.action) {
                case "init":
                    return this.users
                case "add":
                    this.users = [{ ...action.payload }, ...this.users.filter((user: User) => user.email !== action.payload.email)]
                    return [{ ...action.payload }, ...state.filter((user: User) => user.email !== action.payload.email)]
                case "remove":
                    this.users = this.users.filter((user: User) => user._id !== action.payload._id)
                    return state.filter((user: User) => user._id !== action.payload._id)
                case "update":
                    this.users = [{ ...action.payload }, ...this.users.filter((user: User) => user._id !== action.payload._id)]
                    const index = state.findIndex((user: User) => user._id === action.payload._id);
                    if (index !== -1) {
                        const newState = [...state];
                        newState[index] = { ...state[index], ...action.payload }
                        return newState;
                    }
                    return state;
                case "filter":
                    return this.filtered
                default:
                    return state
            }
        }, []),
        shareReplay()
    )

    constructor(private httpService: HttpClient, private readonly notificationService: CustomNotificationService) { }

    create(user: User, headshot?: File) {
        const action: UserStateAction = {
            action: "add",
            payload: user
        }

        const formData = this.getFormData(action.payload, headshot)

        return this.httpService.post<User>(`${environment.apiUrl}/users`, formData)
            .pipe(
                tap(x => this.notificationService.success('User created sucessfully.')),
                map(x => {
                    this.action$.next({ ...action, payload: x })
                    this.producers$.next(null)
                    return x
                }),
                catchError(x => {
                    this.notificationService.error('User was not created.')
                    return throwError(() => x)
                })
            )
    }

    update(user: User, headshot?: File) {
        const action: UserStateAction = {
            action: "update",
            payload: user
        }

        const formData = this.getFormData(user, headshot)

        return this.httpService.put<User>(`${environment.apiUrl}/users/${action.payload._id}`, formData)
            .pipe(
                tap(x => this.notificationService.success('User updated sucessfully.')),
                map(user => {
                    if (headshot) {
                        //clear image cache
                        this.action$.next({ ...action, payload: { ...user, headshot: `${user.headshot}?v=1` } })
                    } else {
                        this.action$.next({ ...action, payload: user })
                    }
                    this.producers$.next(null)
                    return user
                }),
                catchError(x => {
                    this.notificationService.error('User was not updated.')
                    return throwError(() => x)
                })
            )
    }

    delete(user: User) {
        const action: UserStateAction = {
            action: "remove",
            payload: user
        }
        this.httpService.delete<User>(`${environment.apiUrl}/users/${action.payload._id}`)
            .pipe(
                tap(x => this.notificationService.success('User deleted sucessfully.')),
                map(x => {
                    this.action$.next(action)
                    this.producers$.next(null)
                }),
                catchError(x => {
                    this.notificationService.error('User was not deleted.')
                    return throwError(() => x)
                })
            ).subscribe()
    }

    get(query: string = '', page: number = 1) {
        const action: UserStateAction = {
            action: "init",
            payload: {} as User
        }
        if (!query && page == 1 && this.users.length) {
            this.action$.next(action)
            this.searchText = ''
        } else {
            this.search(query, page)
                .pipe(
                    map(x => {
                        if (!query) {
                            this.users = [...this.users, ...x]
                        } else {
                            this.filtered = this.searchText == query ? [...this.filtered, ...x] : x
                            action.action = "filter"
                        }
                        this.searchText = query
                        this.action$.next(action)
                    }),
                    catchError(x => {
                        this.notificationService.error('An error ocurrs during the search.')
                        return throwError(() => x)
                    })
                ).subscribe()
        }
    }

    getProducers() {
        if (this.producers$.value) {
            return this.producers$
        } else {
            return this.httpService.get(`${environment.apiUrl}/users/producers`).pipe(
                map((producers: any) => {
                    const result = producers.map((p: any, i: any) => {
                        return {
                            label: `${p.firstName} ${p.lastName}`,
                            value: p._id!,
                            ref: p
                        }
                    })
                    this.producers$.next(result)
                    return result
                }))
        }
    }

    search(query: string, page: number = 1) {
        return this.httpService.post<User[]>(`${environment.apiUrl}/users/search`, { query, page })
    }

    private getFormData(user: User, headshot?: File): FormData {
        const formData = new FormData();
        if (headshot) {
            const fileName = `${user._id}.jpeg`;
            user.headshot = fileName;
            formData.append('file', new File([headshot], fileName));
        }
        for (const key in user) {
            if (user.hasOwnProperty(key)) {
                const value = user[key];
                if (Array.isArray(value)) {
                    if (value.length > 0) {
                        value.forEach((element: string) => {
                            formData.append(key, element);
                        });
                    } else {
                        formData.append(key, ''); // Append an empty string for an empty array
                    }
                } else if (typeof value === 'string' || value instanceof Blob) {
                    formData.append(key, value);
                } else {
                    formData.append(key, String(value));
                }
            }
        }

        return formData;
    }

    getByEmail(email: string) {
        return this.httpService.get<User>(`${environment.apiUrl}/users/search/${email}`)
    }

}