<ng-container *ngIf="callsheets; else loading">
    <app-panel *ngFor="let callsheet of featuresCallsheets" (onActionEvent)="handleActions($event, callsheet)"
        [actions]="actionsPanel" [moreOptions]="moreOptionsPanel">
        <app-upcoming-callsheet [callsheet]="callsheet" [showProjectUrl]="showProjectUrl"></app-upcoming-callsheet>
    </app-panel>
    <ap-table #table [data]="callsheets" [condensed]="true" [striped]="true" *ngIf="callsheets.length">
        <table>
            <thead>
                <tr>
                    <th>
                        <span>Name</span>
                    </th>
                    <th>
                        <span>Shoot date</span>
                    </th>
                    <th>
                        <span>Reference</span>
                    </th>
                    <th>
                        <span>Created</span>
                    </th>
                    <th>
                        <span>Updated</span>
                    </th>
                    <th>
                        <span>Actions</span>
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let callsheet of table.data; trackBy: trackId let i = index"
                    [ngClass]="{'selected': i === selectedRow}">
                    <td class="wrap_column">
                        <a [href]="workfrontUrl + '/project/' + callsheet.projID" target="_blank"
                            *ngIf="showProjectUrl; else projectName" class="ap-link">{{callsheet.projectName}}</a>
                        <ng-template #projectName>
                            <span> {{callsheet.projectName}}</span>
                        </ng-template>
                    </td>
                    <td>
                        <span>{{callsheet.shootDate | date: "MM/dd/YY hh:mma"}}</span>
                    </td>
                    <td>
                        <span>{{callsheet.referenceID}}</span>
                    </td>
                    <td>
                        <span>{{callsheet.createdAt | date: "MM/dd/YY hh:mma"}}</span>
                    </td>
                    <td>
                        <span>{{callsheet.updatedAt | date: "MM/dd/YY hh:mma"}}</span>
                    </td>
                    <td class="d-flex">
                        <ap-button *ngFor="let action of actionsList" [type]="'button'" [btnType]="'text'"
                            [icon]="action.label === 'Select' ? '' : action.icon!" ap-tooltip
                            [tooltipContent]="action.label === 'Select' ? '' : action.label!" [direction]="'top'"
                            (onClick)="handleActions(action.action, callsheet, i)"
                            [label]="action.label === 'Select' ? 'Select' : ''" class="icon"></ap-button>
                        <ap-menu-button *ngIf="moreOptionsList" [icon]="'vertical-more-outline'" [buttonData]="moreOptionsList"
                            class="d-flex justify-content-end align-items-center icon" [compact]="true">
                            <ap-dropdown-list-item *ngFor="let item of moreOptionsList" [item]="item" (onSelectItem)="handleActions(item.value, callsheet)" class="more-options">
                                <span [class]="item.icon"></span>
                            </ap-dropdown-list-item>
                        </ap-menu-button>

                    </td>
                </tr>
            </tbody>
        </table>
    </ap-table>
    <ap-pagination [label]="['Previous', 'Next']" class="justify-content-end" [max]="totalPages"
        (onChange)="onPaginateChange($event)" *ngIf="totalPages > 1"></ap-pagination>
    <app-confirm-modal (onConfirmEvent)="delete()" [title]="'Delete callsheet'"
        [description]="'Are you sure you want to delete this callsheet? Once deleted, this action can&rsquo;t be undone.'"
        [confirmLabel]="'Delete'" [id]="'tableCmpModal'"></app-confirm-modal>
</ng-container>

<ng-template #loading>
    <ng-container *skeleton="true;repeat: feature;height:'240px';className: 'col-12'"></ng-container>
    <ng-container *skeleton="true;repeat: 1;height: '50px';className: 'col-12'"></ng-container>
    <ng-container *skeleton="true;repeat: itemsPerPage;height: '35px';className: 'col-12'"></ng-container>
</ng-template>

<div *ngIf="callsheets?.length === 0 && featuresCallsheets.length === 0 && !archiveCallsheets">
    <p>Currently, there are no upcoming call sheets scheduled.</p>
</div>