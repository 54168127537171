import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../../shared/shared.module';
import { CallsheetsRoutingModule } from './callsheets-routing.module';
import { CreateCallsheetComponent } from './pages/create/create.component';
import { ListCallsheetComponent } from './pages/list/list.component';
import { ViewCallsheetComponent } from './pages/view/view.component';
import { CloneCallsheetComponent } from './pages/clone/clone.component';
import { TableModule } from '@appkit4/angular-components/table';
import { ListModule } from '@appkit4/angular-components/list';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TimepickerModule } from '@appkit4/angular-components/timepicker';
import { SearchModule} from '@appkit4/angular-components/search';
import { TabsModule } from '@appkit4/angular-components/tabs';
import { ArchiveCallsheetComponent } from './pages/archive/archive.component';
import { SkeletonDirective } from 'src/app/shared/directives/skeleton/skeleton.directive';
import { DropdownListItemModule } from '@appkit4/angular-components/dropdown-list-item';
import { VendorsFormComponent } from './components/vendors-form/vendors-form.component';
import { TimePanelComponent } from './components/time-panel/time-panel.component';
import { CallsheetSharedModule } from './shared/callsheet-shared.module';
import { WrapWithUnderscoreDirective } from './directives/appWrapWithUnderscore.directive';
import { SectionSelectorComponent } from './pages/clone/section-selector/section-selector.component';

@NgModule({
  imports: [
    CommonModule,
    CallsheetsRoutingModule,
    SharedModule,
    CallsheetSharedModule,
    TableModule,
    ListModule,
    FormsModule,
    TimepickerModule,
    SearchModule,
    ReactiveFormsModule,
    TabsModule,
    DropdownListItemModule
  ],
  declarations: [
    CreateCallsheetComponent,
    ListCallsheetComponent,
    ViewCallsheetComponent,
    ArchiveCallsheetComponent,
    VendorsFormComponent,
    WrapWithUnderscoreDirective,
    CloneCallsheetComponent,
    SectionSelectorComponent
  ],
  exports: [
    CreateCallsheetComponent,
    ListCallsheetComponent,
    ViewCallsheetComponent,
    ArchiveCallsheetComponent,
    CloneCallsheetComponent,
    SectionSelectorComponent,
    TimePanelComponent,
  ],
  providers: [SkeletonDirective],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]

})
export class CallsheetsModule { }