<div class="d-flex justify-content-between align-items-center title">
    <h5>
        <ng-container *ngIf="showProjectUrl;else projectName">
            <a [href]="projectUrl" class="ap-link" target="_blank">
                <strong class="ap-mr-spacing-4">{{callsheet.projectName }}</strong>
            </a>
            <span class="ap-text-neutral-10 ap-font-13" *ngIf="callsheet.updatedAt">Updated {{callsheet.updatedAt |
                date:'MM/dd/yyyy'}}</span>
        </ng-container>
        <ng-template #projectName><strong class="ap-mr-spacing-4">{{callsheet.projectName }}</strong></ng-template>
    </h5>
    <ap-button class="d-flex justify-content-end" [btnType]="'text'" [icon]="'view-outline'" [label]="'Preview'"
        (onClick)="preview()"></ap-button>
</div>
<div class="row align-items-center">
    <div class="col-12 col-sm-2 col-md-2 d-flex flex-column align-items-center ap-mb-spacing-5 ap-text-neutral-20">
        <strong class="ap-my-spacing-4 ap-font-32">{{callsheet.shootDate | date: "EEE"}}</strong>
        <strong class="ap-my-spacing-4 shootDay">{{callsheet.shootDate | date: "d"}}</strong>
    </div>
    <div class="col-12 col-sm-10 col-md-7 d-flex flex-column">
        <div class="d-flex align-items-center gap-1 ap-bg-neutral-02">
            <span class="Appkit4-icon icon-location-fill"></span>
            <p class="ap-my-spacing-1">{{callsheet.location.name}}, {{callsheet.location.firstStreet}},
                {{callsheet.location.city}}, UTC{{callsheet.location.timezone | number:'2.0' }}:00</p>
        </div>
        <div class="d-flex justify-content-between ap-mt-spacing-5">
            <div class="ap-text-neutral-13" *ngFor="let date of times">
                <p class="ap-my-spacing-1 ap-font-14">{{date.label}}</p>
                <strong>
                    <p class="ap-my-spacing-1 ap-font-24">{{(date.value | date: "hh:mma") || 'TBA'}}</p>
                </strong>
            </div>
        </div>
    </div>
    <div class="col-12 col-sm-12 col-md-3 venueContact " *ngIf="venueContact">
        <p class="ap-my-spacing-1"><strong>Venue Contact</strong></p>
        <p class="ap-my-spacing-1">
            {{venueContact.firstName + ' ' + venueContact.lastName}}<br>
            {{venueContact.phone}}<br>
            {{venueContact.email}}<br>
        </p>
    </div>
</div>