import { Component, OnInit } from '@angular/core';
import { CallsheetsService } from '../../callsheets.service';
import { CallSheet, Talent } from 'src/app/shared/interfaces/callsheet.interface';
import { Observable, switchMap, tap } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { BusinessCardItem } from 'src/app/shared/components/business-card/business-card.component';
import { environment } from 'src/environments/env';


@Component({
  selector: 'app-view-callsheet',
  templateUrl: './view.component.html',
  styleUrls: ['./view.component.scss']
})
export class ViewCallsheetComponent implements OnInit {

  callsheet$!: Observable<CallSheet>
  blobUrl: string = environment.assetsUrl
  isDownloading: boolean = false
  isArchive: boolean = false
  callsheetRefNumber!: string

  constructor(private callsheetsService: CallsheetsService, private router: Router, private route: ActivatedRoute,
  ) { }


  async ngOnInit() {
    this.callsheet$ = this.route.params.pipe(
      switchMap((params: any) => {
        return this.callsheetsService.getById(params.id)
          .pipe(
            tap(x => {
              this.isArchive = new Date(x.shootDate).setUTCHours(0, 0, 0, 0) < new Date().setUTCHours(0, 0, 0, 0)
              this.callsheetRefNumber = x.referenceID
            })
          )
      })
    )
  }

  preview(id: string) {
    this.router.navigate(['/callsheet/', id])
  }

  download(id: string) {
    this.isDownloading = true
    this.callsheetsService.generatePDF(id).subscribe(x => {
      let blob = new Blob([x], { type: "application/pdf" });
      let url = window.URL.createObjectURL(blob);
      let a = document.createElement("a");
      document.body.appendChild(a);
      a.href = url;
      a.download = `PwC callsheet ${this.callsheetRefNumber}`;
      a.click();
      a.remove();
      this.isDownloading = false
    })
  }

  getItems(talent: Talent): BusinessCardItem[] {
    return [
      { icon: 'Appkit4-icon icon-email-fill', content: talent.email, link: false },
      { icon: 'Appkit4-icon icon-call-fill', content: talent.phone, link: false },
    ]
  }

  trackId(index: number, item: any) {
    return item.order;
  }
}

