import { Component, EventEmitter, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/env';
import { OauthAdapterService } from '../core/oauth';
import { ApplicationUser, CustomNavigationItem } from '../shared/interfaces';

@Component({
  selector: 'app-help-page',
  templateUrl: './help-page.component.html',
  styleUrls: ['./help-page.component.scss']
})
export class HelpPageComponent {

  footerContent: string = "© 2023 PwC. All rights reserved. PwC refers to the PwC network and/or one or more of its member firms, each of which is a separate legal entity. Please see <a class='ap-link' href='https://www.pwc.com/structure'>www.pwc.com/structure</a> for further details.";
  footerType: string = 'text';
  isSidebarCollapsed: boolean = false
  initials$: Observable<string>
  navList: Array<CustomNavigationItem> = []
  user!: ApplicationUser

  id: string = 'notification1'
  position: string = 'bottomRight'

  supportUrl: string = environment.supportUrl

  avatarList: any = [
    { value: 'logout', label: 'Logout', iconName: 'lockclosed-locked-outline' },
  ]

  pwcNav: Array<CustomNavigationItem> = [
    {
      name: 'Getting started',
      prefixIcon: 'home',
      route: 'overview',
    },
    {
      name: 'Vendors, location, users and archive',
      prefixIcon: 'truck',
      route: 'vendors-location-users-archive',
    },
    {
      name: 'Clone and import',
      prefixIcon: 'duplicate',
      route: 'clone-import',
    }
  ]

  vendorNav: Array<CustomNavigationItem> = [
    {
      name: 'Overview',
      prefixIcon: 'home',
      route: 'overview',
    },
  ]

  activeIndex = 0;
  steps: any[] = [
    { label: "Start", tooltipText: "Start" },
    { label: "Mid", tooltipText: "Mid" },
    { label: "End" }
  ];

  onActiveIndexChange(index: number): void {
    console.log('current active index is ' + index);
  }



  @Output()
  onCollapseEvent = new EventEmitter<boolean>()


  constructor(private readonly authService: OauthAdapterService, private router: Router) {
    this.initials$ = this.authService.initials$
    this.isSidebarCollapsed = localStorage.getItem('sidebar-collapsed') === "true" || this.isSidebarCollapsed

  }

  ngOnInit(): void {
    this.authService.user$.subscribe((x: ApplicationUser) => {
      this.user = x
      this.navList = this.user.isPwC ? this.pwcNav : this.vendorNav
    })
  }

  onSelectItem($event: any) {
    if ($event.selected.value == 'logout') {
      this.authService.logout()
    }
  }

  onCollapsedItems($event: any) {
    localStorage.setItem('sidebar-collapsed', $event.status)
    this.onCollapseEvent.emit($event.status)
  }

  scrollToSection(section: string) {
    this.router.navigate([], {
      fragment: section,
      queryParamsHandling: 'merge',
    });

  }

  goToHome() {
    this.router.navigate(['/'])
  }

  goToTop() {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    history.replaceState({}, document.title, window.location.pathname);
  }


}
