import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Observable, Subject, debounceTime, distinctUntilChanged, map, shareReplay } from 'rxjs';
import { LocationsService } from 'src/app/main/locations/locations.service';
import { UsersService } from 'src/app/main/users/users.service';
import { CallSheet, CustomDropdownItem, Producer, ShootingLocation } from 'src/app/shared/interfaces';
import { HelperService } from 'src/app/shared/services/helper.service';

@Component({
  selector: 'app-details',
  templateUrl: './details.component.html',
  styleUrls: ['./details.component.scss']
})
export class DetailsComponent {

  @Input() callsheet!: CallSheet
  @Output() onDetailsChangeEvent: EventEmitter<Partial<CallSheet>> = new EventEmitter()

  producerLeadId: string = ''
  producers$!: Observable<CustomDropdownItem<Producer>[]>
  locations$!: Observable<CustomDropdownItem<ShootingLocation>[]>
  minDate: Date = new Date()
  details: any = {}
  inputTextChange = new Subject<{ text: string, prop: string }>()

  constructor(
    private readonly usersService: UsersService,
    private readonly locationService: LocationsService,
    private readonly helperService: HelperService
  ) {
    this.inputTextChange.pipe(
      debounceTime(500),
      distinctUntilChanged())
      .subscribe((value: { text: string, prop: string }) => {
        this.onDetailsChangeEvent.emit({ [value.prop]: this.callsheet[value.prop as keyof CallSheet] != value.text ? value.text : undefined })
      })
  }

  ngOnInit() {
    this.producers$ = this.usersService.getProducers()

    this.locations$ = this.locationService.locations$
      .pipe(
        map((locations: ShootingLocation[]) => {
          return locations.map(l => {
            return {
              label: l.name,
              value: l._id!,
              ref: { ...l }
            }
          })
        }),
        shareReplay(1)
      )

    this.details = {
      projectName: this.callsheet.projectName,
      referenceID: this.callsheet.referenceID,
      crewCall: this.callsheet.shootDate,
      shootDate: [this.callsheet.shootDate, this.callsheet.shootDate],
      filmingStart: this.callsheet.filmingStart,
      talentCall: this.callsheet.talentCall,
      notes: this.callsheet.notes,
      producers: this.callsheet.producers!.map((p: Producer) => {
        if (p.lead) {
          this.producerLeadId = p._id!
        }
        return {
          label: `${p.firstName} ${p.lastName}`,
          value: p._id!,
          checked: true,
          ref: p
        }
      }),
      location: {
        value: this.callsheet.location._id,
        label: this.callsheet.location.name,
        checked: true,
        ref: this.callsheet.location
      },
    }
  }

  setProducerLead($event: any, producers: CustomDropdownItem<Producer>[], id: string) {
    this.producerLeadId = id
    $event.stopPropagation()
    this.onProducersChange(producers)
  }

  onLocationChange(location: ShootingLocation) {
    this.onDetailsChangeEvent.emit({ location: location._id != this.callsheet.location._id ? location : undefined })
  }

  onProducersChange(value: CustomDropdownItem<Producer>[]) {
    if(value?.length == 1){
      this.producerLeadId = value[0].ref._id
    }

    const producers = value.map(x => {
      const { isProducer: _, ...newObj } = x.ref;
      return {
        ...newObj,
        lead: newObj._id == this.producerLeadId
      }
    }) as Producer[]
    const diff: boolean = JSON.stringify(this.callsheet.producers.map(x => { return { _id: x._id, lead: x.lead } })) != JSON.stringify(producers.map(x => { return { _id: x._id, lead: x.lead } }))
    this.onDetailsChangeEvent.emit({ producers: diff ? producers : undefined })
  }

  onTimePickerChange(value: Date, prop: string) {
    const currentDate = this.callsheet[prop] ?? new Date()
    this.onDetailsChangeEvent.emit({
      [prop]: this.helperService.parseTimezoneDate(new Date(currentDate.setHours(value.getHours(), value.getMinutes())), this.callsheet.location.timezone)
    })
  }

  onShootDateChange($event: Date[]) {
    const hasChange = $event.length &&
      this.callsheet.shootDate.getFullYear() != $event[0].getFullYear() ||
      this.callsheet.shootDate.getMonth() != $event[0].getMonth() ||
      this.callsheet.shootDate.getDate() != $event[0].getDate()
    if (hasChange) {
      this.onDetailsChangeEvent.emit({
        shootDate: this.helperService.parseTimezoneDate(new Date(
          this.callsheet.shootDate.setFullYear($event[0].getFullYear(), $event[0].getMonth(), $event[0].getDate())),
          this.callsheet.location.timezone)
      })
    }
  }
}
