export const environment = {
    production: true,
    apiUrl: "https://api-callsheets-dev.creativeops.pwc.com", //"http://localhost:8080",
    discoveryUrl: "https://login-stg.pwc.com:443/openam/oauth2", //"http://localhost:9444/realms/openam",
    clientId: "urn:web:xlos-callsheets:dev", //"webapp-openam",
    scope: "openid profile",
    workfrontUrl: "https://pwcmktg.sb01.workfront.com",
    assetsUrl: 'https://nus2slwbshed001.z13.web.core.windows.net/headshots/',
    envoyInviteUrl: "https://dashboard.envoy.com/visitors/invites/",
    supportUrl: "https://forms.office.com/r/zxPSiPprB9"
};
 