import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http'
import { Observable } from 'rxjs'
import { OAuthService, OAuthStorage } from 'angular-oauth2-oidc'
import { Injectable } from '@angular/core'
import { Location } from '@angular/common';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private readonly authStorage: OAuthStorage, private readonly authService: OAuthService, private location: Location) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token = this.authStorage.getItem('access_token')
    if (token && req.url !== this.authService.revocationEndpoint) {
      const authReq = req.clone({
        headers: req.headers.set('Authorization', 'Bearer ' + token)
      })
      console.log('authReq', authReq)
      return next.handle(authReq)
    } else {
      const params = this.extractQueryParams(location.href)
      if (params['userId'] && params['sessionId']) {
          const wfReq = req.clone({
              headers: req.headers.set('Authorization',`WF ${params['userId']}.${params['sessionId']}`)
          })
          console.log('authReq', wfReq)
          return next.handle(wfReq)
      }
    }
    return next.handle(req)
  }

  private extractQueryParams(urlString: string): { [key: string]: string } {
    const url = new URL(urlString);
    const params = new URLSearchParams(url.search);

    const queryParams: { [key: string]: string } = {};
    params.forEach((value, key) => {
      queryParams[key] = value;
    })
    return queryParams;
  }
}
